import { TOGGLE_NOTIFICATION_MODAL } from './action-types';

const INITIAL_STATE = {
  open: false,
  type: '',
  handleClose: null,
  title: '',
  message: '',
  messageComponent: null,
  actionButtonText: 'COMMON.OK',
  handleActionButtonClick: null,
  titleTranslationObject: {},
  messageTranslationObject: {},
  secondActionButtonText: '',
  handleSecondActionButtonClick: null,
  titleProps: {},
};

export const notificationModalReducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    case TOGGLE_NOTIFICATION_MODAL:
      return {
        ...state,
        open: payload.data.open || false,
        type: payload.data.type,
        handleClose: payload.data.handleClose,
        title: payload.data.title,
        message: payload.data.message || '',
        messageComponent: payload.data.messageComponent,
        actionButtonText: payload.data.actionButtonText || 'COMMON.OK',
        handleActionButtonClick: payload.data.handleActionButtonClick,
        titleTranslationObject: payload.data.titleTranslationObject || {},
        messageTranslationObject: payload.data.messageTranslationObject || {},
        icon: payload.data.icon || null,
        secondActionButtonText: payload.data.secondActionButtonText,
        handleSecondActionButtonClick: payload.data.handleSecondActionButtonClick,
        titleProps: payload.data.titleProps,
      };
    default:
      return state;
  }
};
