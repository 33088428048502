export const lineHeights = {
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  17: '17px',
  18: '18px',
  19: '19px',
  20: '20px',
  21: '21px',
  22: '22px',
  23: '23px',
  24: '24px',
  25: '25px',
  26: '26px',
  27: '27px',
  28: '28px',
  29: '29px',
  30: '30px',
  31: '31px',
  32: '32px',
  33: '33px',
  34: '34px',
  35: '35px',
  36: '36px',
  37: '37px',
  38: '38px',
  39: '39px',
  40: '40px',
  41: '41px',
  42: '42px',
  43: '43px',
  44: '44px',
};
