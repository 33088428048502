import React from 'react';
import { Flex } from 'styled-react-ui-libs';

import Text from '../text/text';
import Theme from '../../theme';

const Footer = () => {
  return (
    <Flex
      marginTop="auto"
      alignItems="center"
      justifyContent="center"
      paddingY={[Theme.space[15], Theme.space[20], Theme.space[25]]}
      paddingX={Theme.space[10]}
      width="100%"
      borderTop={`${Theme.borders[1]} ${Theme.colors.greys[3]}`}
    >
      <Text textAlign="center" fontSize={[Theme.fontSizes[16], Theme.fontSizes[18], Theme.fontSizes[18]]} lineHeight={Theme.lineHeights[24]} color={Theme.colors.black}>
        {'FOOTER.TEXT'}
      </Text>
    </Flex>
  );
};

export default Footer;
