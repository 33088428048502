import { HANDLE_USER_DATA } from '../constants/action-types';
import { loginApi } from '../../../api/requests/auth-api';
import { getErrorMessage } from '../helpers/login-helpers';

export const login = (email, password, setErrorMsg, onSuccessLogin) => {
  return async (dispatch) => {
    try {
      const response = await loginApi(email, password);
      const userData = response?.data?.data;
      console.log(`userData`, userData);
      dispatch(handleUserData(userData));

      if (onSuccessLogin) {
        onSuccessLogin();
      }
    } catch (error) {
      const errorCause = error?.response?.data?.error?.message;
      const message = getErrorMessage(errorCause);

      setErrorMsg(message);
    }
  };
};

export const handleUserData = (userData) => {
  return {
    type: HANDLE_USER_DATA,
    data: userData,
  };
};
