import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Container, Flex, RenderIf } from 'styled-react-ui-libs';

import AuthFooter from './auth-footer';
import RegistrationContainer from '../../registration/components/registration-container';
import LoginContainer from '../../login/components/login-container';
import Tabs from '../../../components/tabs/tabs';
import Theme from '../../../theme';
import routesConstants from '../../../common/routes-constants';
import registrationFooter from '../images/registration-footer.png';
import loginFooter from '../images/login-footer.png';
import { TABS } from '../constants/auth-constants';

const AuthContainer = () => {
  const params = useParams();
  const history = useHistory();
  const tab = params?.tab;
  const tabs = Object.keys(TABS).map((key) => TABS[key]);

  const handleTabChange = (value) => {
    history.push(`${routesConstants.routes.auth.fullPath}${value}`);
  };

  return (
    <Container ml={Theme.space[0]} mr={Theme.space[0]} paddingX={Theme.space[0]} pt={Theme.space[30]} pb={Theme.space[0]} height="100%" maxWidth="100%">
      <Flex alignItems="center" justifyContent="center">
        <Box width={['100%', '360px', '360px']}>
          <Flex alignItems="center" justifyContent="center">
            <Tabs tab={tab} tabs={tabs} handleTabChange={handleTabChange} />
          </Flex>
          <Box pt={Theme.space[40]} paddingX={[Theme.space[10], Theme.space[0], Theme.space[0]]}>
            <RenderIf show={tab === TABS.registration.id}>
              <RegistrationContainer />
            </RenderIf>
            <RenderIf show={tab === TABS.login.id}>
              <LoginContainer />
            </RenderIf>
          </Box>
        </Box>
      </Flex>
      <Box pt={Theme.space[40]}>
        <AuthFooter imageUrl={tab === TABS.registration.id ? registrationFooter : loginFooter} />
      </Box>
    </Container>
  );
};

export default AuthContainer;
