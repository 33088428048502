import React, { useEffect } from 'react';
import I18n, { setLanguage } from 'redux-i18n';
import queryString from 'query-string';
import { withRouter, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-react-ui-libs';

import Routes from './core/routes';
import NotificationModal from './components/notification-modal/notification-modal';
import Theme from './theme';
import constants from './common/constants';
import { translations } from './core/translations';
import { handleLanguageChange } from './components/cached/actions/cached-actions';

import './common/styles/app.scss';

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const language = useSelector((store) => store.cachedReducer.language);
  const params = queryString.parse(location.search);
  const lang = params && params.lang;

  useEffect(() => {
    const selectedLanguage = lang && lang !== language ? lang : language || 'sv';
    const languages = Object.keys(constants.LANGUAGES).map((key) => constants.LANGUAGES[key]);

    if (languages.findIndex((item) => item.code === selectedLanguage) > -1) {
      dispatch(setLanguage(selectedLanguage));
      dispatch(handleLanguageChange(selectedLanguage));
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <I18n translations={translations} initialLang="sv">
      <ThemeProvider theme={Theme}>
        <NotificationModal />
        <div className="main-container">
          <div className="main-container__content">
            <Routes />
          </div>
        </div>
      </ThemeProvider>
    </I18n>
  );
};

export default withRouter(App);
