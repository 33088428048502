const styles = {
  input: {
    width: '0.1px',
    height: '0.1px',
    overflow: 'hidden',
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
  },
  image: { width: '50%', height: '50%', cursor: 'pointer' },
};

export default styles;
