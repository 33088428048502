import React from 'react';
import PropTypes from 'prop-types';
import { Box, Image } from 'styled-react-ui-libs';

const AuthFooter = ({ imageUrl }) => (
  <Box height="100%">
    <Image src={imageUrl} style={{ height: '100%', display: 'block' }} width="100%" alt="Auth Footer Image" />
  </Box>
);

AuthFooter.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default AuthFooter;
