import React, { useEffect } from 'react';

import { scrollTo } from '../../common/helpers';

const ScrollToTop = ({ children }) => {
  useEffect(() => {
    scrollTo('main-app-wrapper');
  }, []);

  return <>{children}</>;
};

export default ScrollToTop;
