import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'styled-react-ui-libs';

import Theme from '../../theme';

const Label = ({ label, labelStyle = {}, required = false, elementRequired, ...rest }, { t }) => (
  <Flex alignItems="center" {...rest}>
    <Text fontFamily={Theme.fonts.sansPro} fontSize={Theme.fontSizes[14]} color={Theme.colors.black} lineHeight="17px" style={labelStyle} mr="4px">
      {t(label)}
    </Text>
    {required && elementRequired ? elementRequired : null}
  </Flex>
);

Label.propTypes = {
  label: PropTypes.string.isRequired,
  labelStyle: PropTypes.object,
  required: PropTypes.bool,
  elementRequired: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

Label.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Label;
