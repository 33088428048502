import { HANDLE_USER_DATA } from '../constants/action-types';

const INITIAL_STATE = {
  userData: null,
};

export const loginReducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    case HANDLE_USER_DATA:
      return { ...state, userData: payload.data };
    default:
      return state;
  }
};
