import React from 'react';
import PropTypes from 'prop-types';
import { Text as DefaultText } from 'styled-react-ui-libs';

const Text = ({ nonTranslatable = false, translationObject = {}, children, ...rest }, { t }) => <DefaultText {...rest}>{nonTranslatable ? children : t(children, translationObject)}</DefaultText>;

Text.contextTypes = {
  t: PropTypes.func.isRequired,
};

Text.propTypes = {
  nonTranslatable: PropTypes.bool,
  translationObject: PropTypes.object,
};

export default Text;
