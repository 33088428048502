import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'styled-react-ui-libs';

import Text from '../text/text';
import Theme from '../../theme';

const Tabs = ({ tabs = [], tab, handleTabChange }) => (
  <Flex>
    {tabs.map(({ id, title }, index) => (
      <Box key={`tab-${id}`} paddingX={Theme.space[24]} borderRight={index === tabs.length - 1 ? 'none' : `${Theme.borders[1]} ${Theme.colors.black}`}>
        <Text fontWeight={Theme.fontWeights.bold} color={tab === id ? Theme.colors.black : Theme.colors.blacks[1]} onClick={() => handleTabChange(id)} style={{ cursor: 'pointer' }}>
          {title}
        </Text>
      </Box>
    ))}
  </Flex>
);

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  tab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleTabChange: PropTypes.func.isRequired,
};

export default Tabs;
