import { forgotPasswordApi } from '../../../api/requests/auth-api';
import { NOTIFICATION_TYPES } from '../../../components/notification-modal/notification-modal-constants';
import { getErrorMessage } from '../helpers/forgot-password-helpers';
import { toggleNotificationModal } from '../../../components/notification-modal/notification-modal-actions';

export const forgotPassword = (email, setErrorMsg, onDone) => {
  return async (dispatch) => {
    try {
      await forgotPasswordApi(email);

      dispatch(
        toggleNotificationModal(
          true,
          NOTIFICATION_TYPES.success,
          'FORGOT_PASSWORD.SUCCESS_MESSAGE',
          null,
          onDone ? onDone : null,
          null,
          null,
          onDone
            ? () => {
                onDone();
                dispatch(toggleNotificationModal(false));
              }
            : null,
        ),
      );
    } catch (error) {
      const errorCause = error?.response?.data?.error?.message;
      const message = getErrorMessage(errorCause);

      setErrorMsg(message);
    }
  };
};
