import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Box, Button, DefaultCheckbox, DefaultInput, Hyperlink, RenderIf } from 'styled-react-ui-libs';

import Label from '../../../components/label/label';
import Text from '../../../components/text/text';
import Theme from '../../../theme';
import styles from '../../../common/styles';
import { FORM_NAME, FIELDS, SUBMIT_BTN_TEST_ID } from '../constants/registration-constants';
import { validate } from '../validators/registration-validator';

const RegistrationForm = ({ handleSubmit, initialValues = {}, handleRegistration, termsAndConditionLink, errorMsg }, { t }) => {
  const [isPasswordField, setIsPasswordField] = useState(true);
  const { firstName, lastName, email, phoneNumber, password, termsAndConditions } = FIELDS;
  const areTermsAccepted = useSelector((store) => store.form && store.form[FORM_NAME] && store.form[FORM_NAME].values && store.form[FORM_NAME].values[termsAndConditions.name]);

  const passwordInputIconStyle = { fontSize: Theme.fontSizes[24], position: 'absolute', right: Theme.space[5], top: Theme.space[31], color: Theme.colors.greys[4], cursor: 'pointer' };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleRegistration)} action="#" noValidate>
        <Text color={Theme.colors.black} fontWeight={Theme.fontWeights.bold} fontSize={Theme.fontSizes[18]} lineHeight={Theme.lineHeights[20]} textAlign="center" mb={Theme.space[8]}>
          {'REGISTRATION.TITLE'}
        </Text>
        <Text color={Theme.colors.black} fontSize={Theme.fontSizes[14]} lineHeight={Theme.lineHeights[16]} textAlign="left" mb={Theme.space[24]}>
          {'REGISTRATION.SUBTITLE'}
        </Text>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultInput}
            required
            label={t(firstName.label)}
            placeholder={t(firstName.placeholder)}
            name={firstName.name}
            dataTestId={firstName.dataTestId}
            id={firstName.dataTestId}
            value={initialValues[firstName.name] || ''}
            labelStyle={styles.label}
            tabIndex="1"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </Box>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultInput}
            required
            label={t(lastName.label)}
            placeholder={t(lastName.placeholder)}
            name={lastName.name}
            dataTestId={lastName.dataTestId}
            id={lastName.dataTestId}
            value={initialValues[lastName.name] || ''}
            labelStyle={styles.label}
            tabIndex="2"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </Box>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultInput}
            required
            label={t(email.label)}
            placeholder={t(email.placeholder)}
            name={email.name}
            dataTestId={email.dataTestId}
            id={email.dataTestId}
            value={initialValues[email.name] || ''}
            labelStyle={styles.label}
            tabIndex="3"
            type="email"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </Box>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultInput}
            required
            label={t(phoneNumber.label)}
            placeholder={t(phoneNumber.placeholder)}
            name={phoneNumber.name}
            dataTestId={phoneNumber.dataTestId}
            id={phoneNumber.dataTestId}
            value={initialValues[phoneNumber.name] || ''}
            labelStyle={styles.label}
            tabIndex="4"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </Box>
        <Box mb={Theme.space[5]} position="relative">
          <Field
            component={DefaultInput}
            required
            label={t(password.label)}
            placeholder={t(password.placeholder)}
            name={password.name}
            dataTestId={password.dataTestId}
            id={password.dataTestId}
            value={initialValues[password.name] || ''}
            style={{
              paddingRight: Theme.space[35],
            }}
            labelStyle={styles.label}
            type={isPasswordField ? 'password' : 'text'}
            tabIndex="5"
            withAbsoluteError
            errorStyles={styles.errors}
          />
          {isPasswordField ? (
            <IoMdEye style={passwordInputIconStyle} onClick={() => setIsPasswordField(!isPasswordField)} />
          ) : (
            <IoMdEyeOff style={passwordInputIconStyle} onClick={() => setIsPasswordField(!isPasswordField)} />
          )}
        </Box>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultCheckbox}
            label={
              <>
                <Label label={t('REGISTRATION.TERMS_LABEL')} display="inline-block" mr="1px" fontSize="13px" />
                <Hyperlink href={termsAndConditionLink} target="_blank" display="inline-block" mr="5px" fontSize="13px" color={Theme.colors.primary}>
                  {t('REGISTRATION.TERMS_LABEL_LINK')}
                </Hyperlink>
              </>
            }
            name={termsAndConditions.name}
            id={termsAndConditions.dataTestId}
            dataTestId={termsAndConditions.dataTestId}
            checked={areTermsAccepted}
            labelStyle={{ wordBreak: 'break-all', whiteSpace: 'unset', paddingLeft: '25px', paddingRight: Theme.space[0], width: 'auto', overflow: 'unset', color: Theme.colors.text }}
            errorStyles={styles.errors}
          />
        </Box>
        <Box mt={Theme.space[24]}>
          <Button variant="primary" type="submit" style={styles.fullWidth} dataTestId={SUBMIT_BTN_TEST_ID}>
            {t('REGISTRATION.BUTTON')}
          </Button>
        </Box>
        <RenderIf show={errorMsg ? true : false}>
          <Text color={Theme.colors.danger} fontSize={Theme.fontSizes[14]} lineHeight={Theme.lineHeights[16]} textAlign="center" mt={Theme.space[15]}>
            {errorMsg}
          </Text>
        </RenderIf>
      </form>
    </Box>
  );
};

RegistrationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

RegistrationForm.propTypes = {
  handleRegistration: PropTypes.func.isRequired,
  termsAndConditionLink: PropTypes.string,
  errorMsg: PropTypes.string,
};

export default reduxForm({
  form: FORM_NAME,
  validate,
  initialValues: {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
  },
  enableReinitialize: true,
  destroyOnUnmount: true,
})(RegistrationForm);
