import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Flex } from 'styled-react-ui-libs';

import AuthFooter from '../../auth/components/auth-footer';
import ForgotPasswordForm from './forgot-password-form';
import Theme from '../../../theme';
import forgotPasswordFooter from '../images/forgot-password-footer.png';
import routesConstants from '../../../common/routes-constants';
import { FIELDS } from '../constants/forgot-password-constants';
import { TABS } from '../../auth/constants/auth-constants';
import { forgotPassword } from '../actions/forgot-password-actions';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState('');

  const handleForgotPassword = (values) => {
    const { email } = FIELDS;
    dispatch(forgotPassword(values[email.name], setErrorMsg, onDoneForgotPassword));
  };

  const onDoneForgotPassword = () => {
    history.push(`${routesConstants.routes.auth.fullPath}${TABS.login.id}`);
  };

  return (
    <Container ml={Theme.space[0]} mr={Theme.space[0]} paddingX={Theme.space[0]} pt={Theme.space[30]} pb={Theme.space[0]} height="100%" maxWidth="100%">
      <Flex alignItems="center" justifyContent="center" paddingY={['40px', '80px', '90px']} paddingX={[Theme.space[10], Theme.space[0], Theme.space[0]]}>
        <ForgotPasswordForm handleForgotPassword={handleForgotPassword} errorMsg={errorMsg} />
      </Flex>
      <Box pt={Theme.space[40]}>
        <AuthFooter imageUrl={forgotPasswordFooter} />
      </Box>
    </Container>
  );
};

export default LoginContainer;
