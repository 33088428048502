export const GET_MY_BICYCLES = {
  method: 'GET',
  route: '/me/bicycles',
};

export const GET_BICYCLE_TYPES = {
  method: 'GET',
  route: '/bicycle-types/',
};

export const SAVE_BICYCLE_DETAILS_IMAGE = {
  method: 'POST',
  route: '/me/bicycles/detail-image',
};

export const UPDATE_BICYCLE_DETAILS = {
  method: 'PUT',
  route: '/me/bicycles',
};
