import React from 'react';
import { Box } from 'styled-react-ui-libs';

import styles from '../../common/styles';

const ElementRequiredLabel = ({ ...rest }) => (
  <Box styles={styles.label} {...rest}>
    *
  </Box>
);

export default ElementRequiredLabel;
