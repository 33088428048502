import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'styled-react-ui-libs';

import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import ScrollToTop from '../components/scroll-to-top/scroll-to-top';
import routesConstants from '../common/routes-constants';
import { TABS } from '../app/auth/constants/auth-constants';

const Routes = () => {
  const userData = useSelector((store) => store.loginReducer.userData);
  const isUserLoggedIn = userData && userData.token && userData.refresh_token ? true : false;

  const renderPageComponent = (route, props) => {
    const Component = route.component;

    return (
      <Flex flexDirection="column" width="100%" height="100%" pt={['80px', '120px', '160px']}>
        <Box id="main-app-wrapper" />
        <Header />
        <ScrollToTop>
          <Component {...route} {...props} />
        </ScrollToTop>
        <Footer />
      </Flex>
    );
  };

  const renderComponent = (route, props) => {
    const { isProtectedRoute } = route;

    if (!isUserLoggedIn && isProtectedRoute) {
      return <Redirect to={`${routesConstants.routes.auth.fullPath}${TABS.login.id}`} />;
    }

    return renderPageComponent(route, props);
  };

  const renderRoutes = (routes) => {
    if (routes && routes.length > 0) {
      return routes.map((route) => {
        return <Route exact path={route.path} render={(props) => renderComponent(route, props)} key={route.prefix} />;
      });
    } else return null;
  };

  return (
    <React.Fragment>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return isUserLoggedIn ? <Redirect to={routesConstants.routes.bicycles.path} /> : <Redirect to={routesConstants.routes.search.path} />;
          }}
        />
        {renderRoutes(Object.keys(routesConstants.routes).map((key) => routesConstants.routes[key]))}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
