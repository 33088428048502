export const FORM_NAME = 'forgotPasswordForm';

export const SUBMIT_BTN_TEST_ID = 'forgot-password-form-button';

export const FIELDS = {
  email: {
    name: 'email',
    label: 'FORGOT_PASSWORD.EMAIL',
    errorRequired: 'FORGOT_PASSWORD.EMAIL_REQUIRED',
    errorValid: 'FORGOT_PASSWORD.EMAIL_VALID',
    dataTestId: 'email-forgot-password',
  },
};
