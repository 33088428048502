import Theme from '../../theme';

const styles = {
  default: {
    border: `${Theme.borders[1]} ${Theme.colors.defaultBorderColor}`,
    color: Theme.colors.blacks[1],
    background: Theme.colors.white,
    fontSize: Theme.fontSizes[14],
    lineHeight: Theme.lineHeights[16],
    fontWeight: Theme.fontWeights['light-bold'],
    borderRadius: Theme.space[22],
    cursor: 'pointer',
  },
  active: {
    color: Theme.colors.white,
    background: Theme.colors.primary,
    fontSize: Theme.fontSizes[14],
    lineHeight: Theme.lineHeights[16],
    fontWeight: Theme.fontWeights['light-bold'],
    borderRadius: Theme.space[22],
  },
};

export default styles;
