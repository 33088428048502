import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { format, startOfDay, addHours } from 'date-fns';
import { Box, Flex, Container } from 'styled-react-ui-libs';

import BicyclesHeader from './bicycles-header';
import BicyclesForm from './bicycles-form';
import Theme from '../../../theme';
import routesConstants from '../../../common/routes-constants';
import { TABS } from '../../auth/constants/auth-constants';
import { FIELDS } from '../constants/bicycles-constants';
import { handleUserData } from '../../login/actions/login-actions';
import { getMyBicycles, getBicycleTypes, uploadBikeDetailsImage, updateBicycleDetails, handleSelectedBicycle, handleGetBicycleTypes, handleGetUserBicycles } from '../actions/bicycles-actions';

const BicyclesContainer = (_, { t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((store) => store.loginReducer.userData);
  const language = useSelector((store) => store.cachedReducer.language);
  const bicycles = useSelector((store) => store.bicyclesReducer.bicycles);
  const bicycle = useSelector((store) => store.bicyclesReducer.bicycle);
  const bicycleTypes = useSelector((store) => store.bicyclesReducer.bicycleTypes);

  const logout = () => {
    dispatch(handleUserData(null));
    history.push(`${routesConstants.routes.auth.fullPath}${TABS.login.id}`);
  };

  const handleTabChange = (bicycleId) => {
    const bicyclesCopy = Object.assign([], bicycles);
    const selectedBicycle = bicyclesCopy.find((item) => item.id === bicycleId);

    if (selectedBicycle) {
      selectedBicycle.purchase_date = selectedBicycle.purchase_date ? format(new Date(selectedBicycle.purchase_date), 'yyyy-MM-dd') : null;
      dispatch(handleSelectedBicycle(selectedBicycle));
    }
  };

  const handleUploadImage = (files, index) => {
    if (!files || !files.length) {
      return;
    }

    dispatch(uploadBikeDetailsImage(files[0], files[0].name, files[0].type, Object.assign({}, bicycle), Object.assign([], bicycles), index));
  };

  const handleSaveBicycle = (values) => {
    const purchase_date = addHours(startOfDay(values[FIELDS.purchaseDate.name]), 12);
    values[FIELDS.purchaseDate.name] = new Date(Date.parse(purchase_date)).toISOString();
    values[FIELDS.price.name] = parseInt(values[FIELDS.price.name]);
    values[FIELDS.type.name] = parseInt(values[FIELDS.type.name]);
    dispatch(updateBicycleDetails(values, Object.assign([], bicycles)));
  };

  useEffect(() => {
    dispatch(getBicycleTypes());
    dispatch(getMyBicycles());

    return () => {
      dispatch(handleGetBicycleTypes([]));
      dispatch(handleGetUserBicycles([]));
      dispatch(handleSelectedBicycle({}));
    };
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <Container ml={Theme.space[0]} mr={Theme.space[0]} paddingX={Theme.space[0]} pt={[Theme.space[20], Theme.space[30], Theme.space[30]]} pb={Theme.space[0]} height="100%" maxWidth="100%">
      <BicyclesHeader firstName={userData?.user?.first_name} lastName={userData?.user?.last_name} bicycles={bicycles} bicycle={bicycle} logout={logout} handleTabChange={handleTabChange} />
      <Flex background="rgba(243, 245, 246, 0.53)" alignItems="center" justifyContent="center" paddingY={[Theme.space[20], Theme.space[48], Theme.space[48]]}>
        <Box width={['100%', '70%', '50%']} paddingX={[Theme.space[10], Theme.space[0], Theme.space[0]]}>
          <BicyclesForm
            initialValues={Object.assign({}, bicycle)}
            bicycle={bicycle}
            bicycleTypes={bicycleTypes}
            language={language}
            images={bicycle.images}
            handleSaveBicycle={handleSaveBicycle}
            handleUploadImage={handleUploadImage}
          />
        </Box>
      </Flex>
    </Container>
  );
};

BicyclesContainer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default BicyclesContainer;
