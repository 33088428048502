import React from 'react';
import PropTypes from 'prop-types';
import { Hyperlink, Image } from 'styled-react-ui-libs';

import Text from '../../../components/text/text';
import Theme from '../../../theme';
import routesConstants from '../../../common/routes-constants';
import searchFooterImage from '../images/search-footer.png';
import { TABS } from '../../auth/constants/auth-constants';

const SearchBottom = ({ _ }, { t }) => {
  return (
    <>
      <Text
        fontSize={[Theme.fontSizes[18], Theme.fontSizes[23], Theme.fontSizes[23]]}
        color={Theme.colors.black}
        lineHeight={Theme.lineHeights[30]}
        textAlign="center"
        translationObject={{
          register: (
            <Hyperlink href={`${routesConstants.routes.auth.fullPath}${TABS.registration.id}`} color={Theme.colors.blues[1]}>
              {t('COMMON.REGISTER')}
            </Hyperlink>
          ),
        }}
      >
        {'SEARCH.TITLE_BOTTOM'}
      </Text>
      <Image pt={[Theme.space[40], '82px', '82px']} src={searchFooterImage} style={{ height: '100%' }} width="100%" alt="Search Footer Image" />
    </>
  );
};

SearchBottom.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SearchBottom;
