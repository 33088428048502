export const space = {
  '-9': '-9px',
  '-8': '-8px',
  '-7': '-7px',
  '-6': '-6px',
  '-5': '-5px',
  '-4': '-4px',
  '-3': '-3px',
  '-2': '-2px',
  '-1': '-1px',
  0: 0,
  1: '1px',
  2: '2px',
  3: '3px',
  4: '4px',
  5: '5px',
  6: '6px',
  7: '7px',
  8: '8px',
  9: '9px',
  10: '10px',
  11: '11px',
  12: '12px',
  13: '13px',
  14: '14px',
  15: '15px',
  16: '16px',
  17: '17px',
  18: '18px',
  19: '19px',
  20: '20px',
  21: '21px',
  22: '22px',
  23: '23px',
  24: '24px',
  25: '25px',
  26: '26px',
  27: '27px',
  28: '28px',
  29: '29px',
  30: '30px',
  31: '31px',
  32: '32px',
  33: '33px',
  34: '34px',
  35: '35px',
  36: '36px',
  37: '37px',
  38: '38px',
  39: '39px',
  40: '40px',
  41: '41px',
  42: '42px',
  43: '43px',
  44: '44px',
  45: '45px',
  46: '46px',
  47: '47px',
  48: '48px',
  49: '49px',
  50: '50px',
  51: '51px',
  52: '52px',
  53: '53px',
  54: '54px',
  55: '55px',
  56: '56px',
  57: '57px',
  58: '58px',
  59: '59px',
  60: '60px',
};
