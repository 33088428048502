import { HANDLE_GET_USER_BICYCLES, HANDLE_SELECTED_BICYCLE, HANDLE_GET_BICYCLE_TYPES } from '../constants/action-types';

const INITIAL_STATE = {
  bicycles: [],
  bicycle: {},
  bicycleTypes: [],
};

export const bicyclesReducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    case HANDLE_GET_USER_BICYCLES:
      return { ...state, bicycles: payload.data };
    case HANDLE_SELECTED_BICYCLE:
      return { ...state, bicycle: payload.data };
    case HANDLE_GET_BICYCLE_TYPES:
      return { ...state, bicycleTypes: payload.data };
    default:
      return state;
  }
};
