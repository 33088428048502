import React from 'react';
import PropTypes from 'prop-types';
import { IoMdLogOut } from 'react-icons/io';
import { Box, Button, Flex } from 'styled-react-ui-libs';

import FilledTabs from '../../../components/filled-tabs/filled-tabs';
import Text from '../../../components/text/text';
import Theme from '../../../theme';

const BicyclesHeader = ({ firstName = '', lastName = '', bicycles = [], bicycle = {}, logout, handleTabChange }) => {
  return (
    <Box paddingX={[Theme.space[10], Theme.space[40], Theme.space[50]]} pb={[Theme.space[20], Theme.space[30], Theme.space[40]]}>
      <Flex position="relative" width="100%" alignItems="center" justifyContent="center">
        <Flex flexDirection="column" mt={[Theme.space[45], Theme.space[0], Theme.space[0]]}>
          <Text
            color={Theme.colors.black}
            fontWeight={Theme.fontWeights.bold}
            fontSize={Theme.fontSizes[23]}
            lineHeight={Theme.lineHeights[24]}
            translationObject={{ name: `${firstName} ${lastName}` }}
          >
            {'BICYCLES.USER_NAME'}
          </Text>
        </Flex>
        <Button variant="default" position="absolute" right={Theme.space[0]} top={Theme.space['-9']} onClick={logout} dataTestId="logout-button">
          <Text mr={Theme.space[10]}>{'BICYCLES.LOGOUT'}</Text>
          <IoMdLogOut />
        </Button>
      </Flex>
      <Flex flexDirection="column" pt={Theme.space[16]}>
        <Text color={Theme.colors.black} fontWeight={Theme.fontWeights.normal} fontSize={Theme.fontSizes[21]} lineHeight={Theme.lineHeights[26]} textAlign="center">
          {'BICYCLES.TITLE'}
        </Text>
        <Text color={Theme.colors.black} fontWeight={Theme.fontWeights.normal} fontSize={Theme.fontSizes[21]} lineHeight={Theme.lineHeights[26]} textAlign="center">
          {'BICYCLES.SUBTITLE'}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" pt={([Theme.space[20]], Theme.space[40], Theme.space[40])}>
        <FilledTabs activeTab={bicycle?.id} tabs={bicycles} handleTabChange={handleTabChange} />
      </Flex>
    </Box>
  );
};

BicyclesHeader.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  bicycles: PropTypes.array,
  bicycle: PropTypes.object,
  logout: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default BicyclesHeader;
