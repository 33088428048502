import React from 'react';
import PropTypes from 'prop-types';
import { FaImages } from 'react-icons/fa';
import { Box, Flex, Grid, GridItem, Image, RenderIf } from 'styled-react-ui-libs';

import Text from '../../../components/text/text';
import UploadBike from '../../../components/upload-bike/upload-bike';
import Theme from '../../../theme';
import bikePlaceholder from '../../../common/images/bike-placeholder.svg';

const BicyclesImages = ({ images, handleUploadImage }) => {
  return (
    <Box mt={Theme.space[8]}>
      <Flex background={Theme.colors.greys[5]} padding={Theme.space[16]} alignItems="center">
        <FaImages style={{ color: Theme.colors.greys[6] }} />
        <Text ml={Theme.space[8]} color={Theme.colors.greys[6]} fontSize={Theme.fontSizes[12]} lineHeight={Theme.lineHeights[16]}>
          {'BICYCLES.UPLOAD_MESSAGE'}
        </Text>
      </Flex>
      <Grid mt={Theme.space[24]} gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr']} gridGap={[Theme.space[10], Theme.space[16], Theme.space[24]]}>
        {images.map(({ url }, index) => (
          <GridItem key={`bike-image-${index}`} borderRadius={Theme.space[8]} height="100%" minHeight="300px">
            <RenderIf show={url ? false : true}>
              <UploadBike onChange={(files) => handleUploadImage(files, index)} acceptFileTypes="image/*" inputTestId={`bike-upload-${index}`} />
            </RenderIf>
            <RenderIf show={url ? true : false}>
              <Flex alignItems="center" justifyContent="center" background={Theme.colors.white} height="100%">
                <Image
                  src={`${url}?${new Date().getTime()}`}
                  alt={`bike-image-${index}`}
                  style={{ width: '100%', height: '300px', borderRadius: Theme.space[8], cursor: 'pointer' }}
                  id={`bike-uploaded-image-${index}`}
                  onClick={() => window.open(url, '_blank').focus()}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = bikePlaceholder;
                    e.target.style = 'background: white; width: 40%;';
                  }}
                />
              </Flex>
            </RenderIf>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

BicyclesImages.propTypes = {
  images: PropTypes.array,
  handleUploadImage: PropTypes.func.isRequired,
};

export default BicyclesImages;
