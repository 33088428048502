import React from 'react';

import Translator from '../../../components/translator/translator';
import { FIELDS } from '../constants/bicycles-constants';

export const validate = (values) => {
  const { type, bicycleId, model, price, groupSet, purchaseDate, ageAndCondition, tyreDiameter, tyreWidth, valveType, gearsAndDrivetrain, frontBreak } = FIELDS;
  const errors = {};

  if (!values[type.name]) {
    errors[type.name] = <Translator>{type.errorRequired}</Translator>;
  }

  if (!values[bicycleId.name]) {
    errors[bicycleId.name] = <Translator>{bicycleId.errorRequired}</Translator>;
  }

  if (!values[model.name]) {
    errors[model.name] = <Translator>{model.errorRequired}</Translator>;
  }

  if (!values[ageAndCondition.name]) {
    errors[ageAndCondition.name] = <Translator>{ageAndCondition.errorRequired}</Translator>;
  }

  if (!values[tyreDiameter.name]) {
    errors[tyreDiameter.name] = <Translator>{tyreDiameter.errorRequired}</Translator>;
  }

  if (!values[tyreWidth.name]) {
    errors[tyreWidth.name] = <Translator>{tyreWidth.errorRequired}</Translator>;
  }

  if (!values[valveType.name]) {
    errors[valveType.name] = <Translator>{valveType.errorRequired}</Translator>;
  }

  if (!values[gearsAndDrivetrain.name]) {
    errors[gearsAndDrivetrain.name] = <Translator>{gearsAndDrivetrain.errorRequired}</Translator>;
  }

  if (!values[frontBreak.name]) {
    errors[frontBreak.name] = <Translator>{frontBreak.errorRequired}</Translator>;
  }

  if (!values[price.name]) {
    errors[price.name] = <Translator>{price.errorRequired}</Translator>;
  }

  if (values[price.name] && parseFloat(values[price.name]) <= 0) {
    errors[price.name] = <Translator>{price.errorValid}</Translator>;
  }

  if (!values[groupSet.name]) {
    errors[groupSet.name] = <Translator>{groupSet.errorRequired}</Translator>;
  }

  if (!values[purchaseDate.name]) {
    errors[purchaseDate.name] = <Translator>{purchaseDate.errorRequired}</Translator>;
  }

  return errors;
};
