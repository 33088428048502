import React from 'react';

import Translator from '../../../components/translator/translator';
import constants from '../../../common/constants';
import { FIELDS } from '../constants/registration-constants';

export const validate = (values) => {
  const { firstName, lastName, email, phoneNumber, password, termsAndConditions } = FIELDS;
  const errors = {};

  if (!values[firstName.name]) {
    errors[firstName.name] = <Translator>{firstName.errorRequired}</Translator>;
  }

  if (!values[lastName.name]) {
    errors[lastName.name] = <Translator>{lastName.errorRequired}</Translator>;
  }

  if (values[email.name] && !constants.EMAIL_REGEX.test(values[email.name])) {
    errors[email.name] = <Translator>{email.errorValid}</Translator>;
  }

  if (!values[email.name]) {
    errors[email.name] = <Translator>{email.errorRequired}</Translator>;
  }

  if (!values[phoneNumber.name]) {
    errors[phoneNumber.name] = <Translator>{phoneNumber.errorRequired}</Translator>;
  }

  if (values[password.name] && values[password.name].length < 3) {
    errors[password.name] = <Translator>{password.errorValid}</Translator>;
  }

  if (!values[password.name]) {
    errors[password.name] = <Translator>{password.errorRequired}</Translator>;
  }

  if (!values[termsAndConditions.name]) {
    errors[termsAndConditions.name] = <Translator>{termsAndConditions.errorRequired}</Translator>;
  }

  return errors;
};
