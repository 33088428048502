import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'styled-react-ui-libs';

import Theme from '../../theme';
import styles from './styles';

const FilledTabs = ({ tabs = [], activeTab, handleTabChange }) => {
  const onTabClick = (value) => {
    if (activeTab === value) {
      return;
    }

    handleTabChange(value);
  };

  return (
    <Flex flexWrap="wrap" justifyContent={['center', 'unset', 'unset']}>
      {tabs.map((tab, index) => (
        <Flex
          key={`filled-tab-${tab.id}`}
          style={tab.id === activeTab ? styles.active : styles.default}
          mb={Theme.space[5]}
          mr={index === tabs.length - 1 ? Theme.space[0] : [Theme.space[4], Theme.space[8], Theme.space[8]]}
          onClick={() => onTabClick(tab.id)}
          alignItems="center"
          justifyContent="center"
          paddingY={Theme.space[10]}
          paddingX={Theme.space[24]}
          id={`filled-tab-${index + 1}`}
        >
          {tab.name}
        </Flex>
      ))}
    </Flex>
  );
};

FilledTabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleTabChange: PropTypes.func.isRequired,
};

export default FilledTabs;
