import { format } from 'date-fns';

import { getBicycleApi } from '../../../api/requests/bicycles-api';
import { handleApiError } from '../../../common/helpers';
import { HANDLE_GET_BICYCLE, HANDLE_GET_BICYCLE_FIELDS } from '../constants/action-types';
import { FIELDS } from '../constants/bicycle-constants';

export const getBicycle = (serial) => {
  return async (dispatch) => {
    try {
      const response = await getBicycleApi(serial);

      const bicycle = response?.data?.data;
      let bicycleFields = Object.assign([], FIELDS);

      if (bicycle) {
        bicycle.purchase_date = bicycle.purchase_date ? format(new Date(bicycle.purchase_date), 'yyyy-MM-dd') : null;

        bicycleFields = FIELDS.map((field) => {
          return { ...field, value: (field.tookSerial ? serial : bicycle[field.name]) || '' };
        });
      }

      dispatch(handleGetBicycle(bicycle));
      dispatch(handleGetBicycleFields(bicycleFields));
    } catch (error) {
      handleApiError(error);
    }
  };
};

export const handleGetBicycle = (bicycle) => {
  return {
    type: HANDLE_GET_BICYCLE,
    data: bicycle,
  };
};

export const handleGetBicycleFields = (bicycleFields) => {
  return {
    type: HANDLE_GET_BICYCLE_FIELDS,
    data: bicycleFields,
  };
};
