import { registrationApi } from '../../../api/requests/auth-api';
import { NOTIFICATION_TYPES } from '../../../components/notification-modal/notification-modal-constants';
import { getErrorMessage } from '../helpers/registration-helpers';
import { toggleNotificationModal } from '../../../components/notification-modal/notification-modal-actions';
import { handleUserData } from '../../login/actions/login-actions';

export const registration = (firstName, lastName, phoneNumber, email, password, language, country, setErrorMsg, onSuccess) => {
  return async (dispatch) => {
    try {
      const response = await registrationApi(firstName, lastName, phoneNumber, email, password, language, country);
      const userData = response?.data?.data;
      dispatch(handleUserData(userData));

      const handleCloseNotificationModal = () => {
        dispatch(toggleNotificationModal(false));

        if (onSuccess) {
          onSuccess();
        }
      };

      dispatch(toggleNotificationModal(true, NOTIFICATION_TYPES.success, 'REGISTRATION.SUCCESS_MESSAGE', null, handleCloseNotificationModal, null, null, handleCloseNotificationModal));
    } catch (error) {
      const errorCause = error?.response?.data?.error?.message;
      const message = getErrorMessage(errorCause);

      setErrorMsg(message);
    }
  };
};
