export const TABS = {
  registration: {
    id: 'registration',
    title: 'AUTH.REGISTRATION',
  },
  login: {
    id: 'login',
    title: 'AUTH.LOGIN',
  },
};
