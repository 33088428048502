import axios from 'axios';
import promise from 'promise';

import constants from '../common/constants';
import routesConstants from '../common/routes-constants';
import { TABS } from '../app/auth/constants/auth-constants';
import { store } from '../index';
import { blockPageScroll, unblockPageScroll } from '../common/helpers';
import { getNewRefreshTokenApi } from '../api/requests/auth-api';
import { handleUserData } from '../app/login/actions/login-actions';

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const initilizeHttpClient = () => {
  const instance = axios.create();
  instance.defaults.headers.common = {};
  axios.defaults.baseURL = constants.API_URL;

  axios.interceptors.request.use(
    (config) => {
      blockPageScroll();

      const reducerState = store.getState();
      const userData = reducerState?.loginReducer?.userData;
      const language = reducerState?.cachedReducer?.language;

      // if request url is towards our API, send appropriate headers
      if (!config.isExternalRequest && config.method !== 'OPTIONS') {
        config.headers['app-platform'] = constants.SOURCE;

        if (userData?.token) {
          config.headers.Authorization = `Bearer ${userData.token}`;
        }

        config.headers['Accept-Language'] = language || constants.LANGUAGES.en.code;
      }

      return config;
    },
    (error) => {
      unblockPageScroll();

      return promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => {
      unblockPageScroll();
      return response;
    },
    async (error) => {
      unblockPageScroll();

      const status = error?.response?.status;
      const originalRequest = error?.config;

      if (status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          const reducerState = store.getState();
          const userData = reducerState?.loginReducer?.userData;
          isAlreadyFetchingAccessToken = true;

          try {
            const response = await getNewRefreshTokenApi(userData.refresh_token);
            const tokenData = response?.data?.data;

            if (tokenData.token && tokenData.refresh_token) {
              const userDataCopy = Object.assign({}, userData);
              userDataCopy.token = tokenData.token;
              userDataCopy.refresh_token = tokenData.refresh_token;
              isAlreadyFetchingAccessToken = false;
              store.dispatch(handleUserData(userDataCopy));
              onAccessTokenFetched(tokenData.token);
            }
          } catch (error) {
            localStorage.removeItem('persist:root');
            window.location.href = `${routesConstants.routes.auth.fullPath}${TABS.login.id}`;
          }
        }

        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber(() => {
            resolve(axios(originalRequest));
          });
        });

        return retryOriginalRequest;
      }

      return Promise.reject(error);
    },
  );
};

export default initilizeHttpClient;
