import constants from '../../../common/constants';

export const FORM_NAME = 'bicyclesForm';

export const SUBMIT_BTN_TEST_ID = 'bicycles-form-button';

export const FIELDS = {
  type: {
    name: 'bicycle_type_id',
    label: 'BICYCLES.TYPE',
    placeholder: 'BICYCLES.TYPE_PLACEHOLDER',
    errorRequired: 'BICYCLES.TYPE_REQUIRED',
    dataTestId: 'bicycle-type',
  },
  bicycleId: {
    name: 'serial_number',
    label: 'BICYCLES.ID',
    errorRequired: 'BICYCLES.ID_REQUIRED',
    dataTestId: 'bicycle-id',
  },
  model: {
    name: 'name',
    label: 'BICYCLES.MODEL',
    errorRequired: 'BICYCLES.MODEL_REQUIRED',
    dataTestId: 'bicycle-model',
  },
  ageAndCondition: {
    name: 'age_and_condition',
    label: 'BICYCLES.AGE_AND_CONDITION',
    placeholder: 'BICYCLES.AGE_AND_CONDITION_PLACEHOLDER',
    errorRequired: 'BICYCLES.AGE_AND_CONDITION_REQUIRED',
    dataTestId: 'bicycle-age-and-condition',
  },
  tyreWidth: {
    name: 'tyre_size',
    label: 'BICYCLES.TYRE_WIDTH',
    placeholder: 'BICYCLES.TYRE_WIDTH_PLACEHOLDER',
    errorRequired: 'BICYCLES.TYRE_WIDTH_REQUIRED',
    dataTestId: 'bicycle-tyre-width',
  },
  tyreDiameter: {
    name: 'tyre_diameter',
    label: 'BICYCLES.TYRE_DIAMETER',
    placeholder: 'BICYCLES.TYRE_DIAMETER_PLACEHOLDER',
    errorRequired: 'BICYCLES.TYRE_DIAMETER_REQUIRED',
    dataTestId: 'bicycle-tyre-diameter',
  },
  valveType: {
    name: 'valve_type',
    label: 'BICYCLES.VALVE_TYPE',
    placeholder: 'BICYCLES.VALVE_TYPE_PLACEHOLDER',
    errorRequired: 'BICYCLES.VALVE_TYPE_REQUIRED',
    dataTestId: 'bicycle-valve-type',
  },
  gearsAndDrivetrain: {
    name: 'drivetrain',
    label: 'BICYCLES.DRIVETRAIN',
    placeholder: 'BICYCLES.DRIVETRAIN_PLACEHOLDER',
    errorRequired: 'BICYCLES.DRIVETRAIN_REQUIRED',
    dataTestId: 'bicycle-drivetrain',
  },
  frontBreak: {
    name: 'brake_front_and_rear',
    label: 'BICYCLES.FRONT_BREAK',
    placeholder: 'BICYCLES.FRONT_BREAK_PLACEHOLDER',
    errorRequired: 'BICYCLES.FRONT_BREAK_REQUIRED',
    dataTestId: 'bicycle-front-break',
  },
  price: {
    name: 'price',
    label: 'BICYCLES.PRICE',
    errorRequired: 'BICYCLES.PRICE_REQUIRED',
    errorValid: 'BICYCLES.PRICE_VALID',
    dataTestId: 'bicycle-price',
  },
  groupSet: {
    name: 'groupset',
    label: 'BICYCLES.GROUP_SET',
    placeholder: 'BICYCLES.GROUP_SET_PLACEHOLDER',
    errorRequired: 'BICYCLES.GROUP_SET_REQUIRED',
    dataTestId: 'bicycle-group-set',
  },
  lockModel: {
    name: 'lock_model',
    label: 'BICYCLES.LOCK_MODEL',
    dataTestId: 'bicycle-lock-model',
  },
  purchaseDate: {
    name: 'purchase_date',
    label: 'BICYCLES.PURCHASE_DATE',
    errorRequired: 'BICYCLES.PURCHASE_DATE_REQUIRED',
    dataTestId: 'bicycle-purchase-date',
  },
};

export const AGE_AND_CONDITIONS = [
  {
    value: '0-2 year or new condition',
    text: 'BICYCLES.ZERO_TO_TWO_OLD',
  },
  {
    value: '2-5 years or good condition',
    text: 'BICYCLES.TWO_TO_FIVE_OLD',
  },
  {
    value: '5+ years or needs upgrade',
    text: 'BICYCLES.FIVE_PLUS_OLD',
  },
  {
    value: 'Worn out',
    text: 'BICYCLES.WORN_OUT',
  },
];

export const BRAKES_OPTIONS = [
  {
    value: 'Hydraulic Disc',
    text: 'BICYCLES.HYDRAULIC_DISC',
  },
  {
    value: 'Mechanical Disc',
    text: 'BICYCLES.MECHANICAL_DISC',
  },
  {
    value: 'Caliper (Road)',
    text: 'BICYCLES.CALIPER',
  },
  {
    value: 'V-brake',
    text: 'BICYCLES.V_BREAK',
  },
  {
    value: 'Drum Brake',
    text: 'BICYCLES.DRUM_BREAK',
  },
  {
    value: 'Cantilever',
    text: 'BICYCLES.CANTILEVER',
  },
  {
    value: constants.OTHER_DROPDOWN_VALUE,
    text: 'COMMON.OTHER',
  },
];

export const TYRE_WIDTHS = [
  {
    value: '25',
    text: '25',
  },
  {
    value: '28',
    text: '28',
  },
  {
    value: '30-33',
    text: '30-33',
  },
  {
    value: '35-38',
    text: '35-38',
  },
  {
    value: '40-43',
    text: '40-43',
  },
  {
    value: '44-47',
    text: '44-47',
  },
  {
    value: constants.OTHER_DROPDOWN_VALUE,
    text: 'COMMON.OTHER',
  },
];

export const TYRE_DIAMETERS = [
  {
    value: '622/28" (700c/29")',
    text: '622/28" (700c/29")',
  },
  {
    value: '584/27.5" (650b)',
    text: '584/27.5" (650b)',
  },
  {
    value: '559/26"',
    text: '559/26"',
  },
  {
    value: '507/24"',
    text: '507/24"',
  },
  {
    value: '406/20"',
    text: '406/20"',
  },
  {
    value: '305/16"',
    text: '305/16"',
  },
  {
    value: constants.OTHER_DROPDOWN_VALUE,
    text: 'COMMON.OTHER',
  },
];

export const VALVE_OPTIONS = [
  {
    value: 'Presta',
    text: 'BICYCLES.VALVE_PRESTA',
  },
  {
    value: 'Dunlop',
    text: 'BICYCLES.VALVE_DUNLOP',
  },
  {
    value: 'Schrader',
    text: 'BICYCLES.VALVE_SCHRADER',
  },
];
