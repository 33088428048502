import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LoginForm from './login-form';
import routesConstants from '../../../common/routes-constants';
import { FIELDS } from '../constants/login-constants';
import { login } from '../actions/login-actions';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorMsg, setErrorMsg] = useState('');

  const onSuccessLogin = () => {
    history.push(routesConstants.routes.bicycles.path);
  };

  const handleLogin = (values) => {
    const { email, password } = FIELDS;

    dispatch(login(values[email.name], values[password.name], setErrorMsg, onSuccessLogin));
  };

  return <LoginForm handleLogin={handleLogin} errorMsg={errorMsg} />;
};

export default LoginContainer;
