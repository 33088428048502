import Theme from '../../theme';

const styles = {
  defaultInput: {
    border: `${Theme.borders[1]} ${Theme.colors.defaultBorderColor}`,
  },
  defaultButton: {
    fontWeight: Theme.fontWeights.bold,
    paddingLeft: Theme.space[32],
    paddingRight: Theme.space[32],
    fontSize: Theme.fontSizes[14],
    lineHeight: Theme.lineHeights[17],
  },
};

export default styles;
