import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';
import { persistCombineReducers } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';

import constants from '../common/constants';

import { loginReducer } from '../app/login/reducers/login-reducer';

import { cachedReducer } from '../components/cached/reducers/cached-reducer';
import { notificationModalReducer } from '../components/notification-modal/notification-modal-reducer';

import { bicyclesReducer } from '../app/bicycles/reducers/bicycles-reducer';
import { bicycleReducer } from '../app/bicycle/reducers/bicycle-reducer';

const encryptor = encryptTransform({
  secretKey: constants.STORAGE_SECRET_KEY,
});

export const config = {
  key: 'root',
  // only these will be saved in persist state
  whitelist: ['cachedReducer', 'loginReducer'],
  // these will not be persisted
  blacklist: [],
  storage,
  transforms: constants.ENVIRONMENT === 'local' || constants.ENVIRONMENT === 'development' ? [] : [encryptor],
};

const rootReducer = persistCombineReducers(config, {
  loginReducer,
  cachedReducer,
  notificationModalReducer,
  bicyclesReducer,
  bicycleReducer,
  i18nState,
  form: formReducer,
});

export default rootReducer;
