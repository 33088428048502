import Theme from '../../theme';

const styles = {
  label: {
    color: Theme.colors.black,
    fontWeight: Theme.fontWeights.bold,
    fontSize: Theme.fontSizes[14],
  },
  fullWidth: {
    width: '100%',
  },
  select: {
    borderColor: Theme.colors.defaultBorderColor,
    height: Theme.space[37],
  },
  errors: {
    fontSize: Theme.fontSizes[14],
  },
};

export default styles;
