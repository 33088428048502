import { FIELDS } from '../constants/bicycle-constants';
import { HANDLE_GET_BICYCLE, HANDLE_GET_BICYCLE_FIELDS } from '../constants/action-types';

const INITIAL_STATE = {
  bicycle: { images: [] },
  bicycleFields: FIELDS,
};

export const bicycleReducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    case HANDLE_GET_BICYCLE:
      return { ...state, bicycle: payload.data };
    case HANDLE_GET_BICYCLE_FIELDS:
      return { ...state, bicycleFields: payload.data };
    default:
      return state;
  }
};
