import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Flex } from 'styled-react-ui-libs';

import SearchTop from './search-top';
import SearchBottom from './search-bottom';
import SearchInput from '../../../components/search-input/search-input';
import Theme from '../../../theme';
import routesConstants from '../../../common/routes-constants';
import { searchBicycle } from '../actions/search-actions';

const SearchContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const handleSearchBicycle = () => {
    dispatch(searchBicycle(searchValue, onSearchSuccess));
  };

  const onSearchSuccess = () => {
    history.push(`${routesConstants.routes.bicycle.fullPath}${searchValue}`);
  };

  return (
    <Container ml={Theme.space[0]} mr={Theme.space[0]} height="100%" maxWidth="100%">
      <Flex width="100%">
        <Flex width="100%" alignItems="center" justifyContent="center">
          <Flex width={['100%', '100%', '80%', '80%']} flexDirection="column" px={[Theme.space[10], Theme.space[10], Theme.space[0], Theme.space[0]]} alignItems="center" justifyContent="center">
            <SearchTop />
            <Flex pt={Theme.space[24]} alignItems="center" justifyContent="center" width={['100%', '80%', '65%']}>
              <SearchInput
                value={searchValue}
                onEnter={handleSearchBicycle}
                onButtonClick={handleSearchBicycle}
                onChange={setSearchValue}
                inputProps={{ height: Theme.space[55] }}
                buttonProps={{ height: Theme.space[55] }}
                showButton
                disabledAction={!searchValue || searchValue.length < 3}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex width="100%" pt={[Theme.space[40], '82px', '82px']} alignItems="center" justifyContent="center">
        <Flex width={['100%', '100%', '85%', '85%']} flexDirection="column" px={[Theme.space[10], Theme.space[10], Theme.space[0], Theme.space[0]]} alignItems="center" justifyContent="center">
          <SearchBottom />
        </Flex>
      </Flex>
    </Container>
  );
};

export default SearchContainer;
