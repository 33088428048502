import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import RegistrationForm from './registration-form';
import routesConstants from '../../../common/routes-constants';
import { FIELDS } from '../constants/registration-constants';
import { registration } from '../actions/registration-actions';
import constants from '../../../common/constants';

const RegistrationContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const language = useSelector((store) => store.cachedReducer.language);
  const [errorMsg, setErrorMsg] = useState('');

  const country = language === constants.LANGUAGES.en.code ? constants.COUNTRIES.england.code : constants.COUNTRIES.sweden.code;

  const termsAndConditionLink = language === constants.LANGUAGES.en.code ? constants.EN_TERMS_AND_CONDITIONS_LINK : constants.SV_TERMS_AND_CONDITIONS_LINK;

  const onSuccessRegistration = () => {
    history.push(routesConstants.routes.bicycles.path);
  };

  const handleRegistration = (values) => {
    const { firstName, lastName, email, phoneNumber, password } = FIELDS;

    dispatch(registration(values[firstName.name], values[lastName.name], values[phoneNumber.name], values[email.name], values[password.name], language, country, setErrorMsg, onSuccessRegistration));
  };

  return <RegistrationForm handleRegistration={handleRegistration} termsAndConditionLink={termsAndConditionLink} errorMsg={errorMsg} />;
};

export default RegistrationContainer;
