import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Box, RenderIf, DefaultSelect, DefaultInput } from 'styled-react-ui-libs';

import ElementRequiredLabel from '../element-required-label/element-required-lable';
import Theme from '../../theme';
import constants from '../../common/constants';
import styles from '../../common/styles';

const DropdownWithInput = ({ optionsKey = '', initialValue, options = [], fieldOptions = {}, disabled = false, required = false, changeField, formName, tabIndex, ...rest }, { t }) => {
  const [isTextInput, setIsTextInput] = useState(false);
  const value = useSelector((store) => store.form && store.form[formName] && store.form[formName].values && store.form[formName].values[fieldOptions.name]);
  const disabledStyles = disabled ? { backgroundColor: Theme.colors.disabled, color: Theme.colors.black } : {};

  const isTextInputDisplayed = (selectedValue) => {
    if (!selectedValue) {
      return false;
    }

    return selectedValue === constants.OTHER_DROPDOWN_VALUE ? true : options.findIndex((item) => item[optionsKey].toString() === selectedValue.toString()) === -1;
  };

  const handleSetIsGroupSetTextInput = () => {
    if (disabled) {
      return;
    }

    if (changeField) {
      changeField(fieldOptions.name, '');
    }
  };

  useEffect(() => {
    const isInput = value === null || isTextInputDisplayed(value);

    if (value === constants.OTHER_DROPDOWN_VALUE) {
      changeField(fieldOptions.name, null);
    }

    setIsTextInput(isInput);
    // eslint-disable-next-line
  }, [value]);

  return (
    <Box position="relative" pb={isTextInput ? Theme.space[0] : Theme.space[20]}>
      <RenderIf show={!isTextInput}>
        <DefaultSelect
          options={options}
          required={required}
          elementRequired={required ? <ElementRequiredLabel /> : null}
          disabled={disabled}
          hideArrow={disabled}
          label={t(fieldOptions.label)}
          placeholder={t(fieldOptions.placeholder)}
          name={fieldOptions.name}
          dataTestId={fieldOptions.dataTestId}
          id={fieldOptions.dataTestId}
          value={initialValue}
          labelStyle={styles.label}
          style={{ ...styles.select, ...disabledStyles }}
          withAbsoluteError
          errorStyles={styles.errors}
          tabIndex={tabIndex}
          {...rest}
        />
      </RenderIf>
      <RenderIf show={isTextInput}>
        <DefaultInput
          required={required}
          elementRequired={required ? <ElementRequiredLabel /> : null}
          disabled={disabled}
          label={t(fieldOptions.label)}
          name={fieldOptions.name}
          dataTestId={fieldOptions.dataTestId}
          id={fieldOptions.dataTestId}
          value={initialValue}
          labelStyle={styles.label}
          style={{ paddingRight: Theme.space[35] }}
          withAbsoluteError
          errorStyles={styles.errors}
          tabIndex={tabIndex}
          {...rest}
        />
        <Box position="absolute" right={Theme.space[5]} bottom={Theme.space[23]}>
          <AiOutlineCloseCircle onClick={handleSetIsGroupSetTextInput} style={{ fontSize: Theme.fontSizes[24], cursor: disabled ? 'not-allowed' : 'pointer' }} />
        </Box>
      </RenderIf>
    </Box>
  );
};

DropdownWithInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

DropdownWithInput.propTypes = {
  optionsKey: PropTypes.oneOfType(['string', 'number']),
  value: PropTypes.oneOfType(['string', 'number']),
  options: PropTypes.array,
  fieldOptions: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  changeField: PropTypes.func,
  formName: PropTypes.string.isRequired,
  tabIndex: PropTypes.oneOfType(['string', 'number']),
};

export default DropdownWithInput;
