import React from 'react';

import Translator from '../../../components/translator/translator';
import constants from '../../../common/constants';
import { FIELDS } from '../constants/login-constants';

export const validate = (values) => {
  const { email, password } = FIELDS;
  const errors = {};

  if (values[email.name] && !constants.EMAIL_REGEX.test(values[email.name])) {
    errors[email.name] = <Translator>{email.errorValid}</Translator>;
  }

  if (!values[email.name]) {
    errors[email.name] = <Translator>{email.errorRequired}</Translator>;
  }

  if (!values[password.name]) {
    errors[password.name] = <Translator>{password.errorRequired}</Translator>;
  }

  return errors;
};
