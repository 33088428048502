export const FORM_NAME = 'loginForm';

export const SUBMIT_BTN_TEST_ID = 'login-form-button';

export const FIELDS = {
  email: {
    name: 'email',
    label: 'REGISTRATION.EMAIL',
    errorRequired: 'REGISTRATION.EMAIL_REQUIRED',
    errorValid: 'REGISTRATION.EMAIL_VALID',
    dataTestId: 'email-login',
  },
  password: {
    name: 'password',
    label: 'REGISTRATION.PASSWORD',
    errorRequired: 'REGISTRATION.PASSWORD_REQUIRED',
    dataTestId: 'password-login',
  },
};
