import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DefaultInput, Flex, RenderIf } from 'styled-react-ui-libs';

import styles from '../../common/styles';
import searchStyle from './style';
import Theme from '../../theme';

const SearchInput = (
  {
    name = 'search',
    label = '',
    placeholder = 'COMMON.SEARCH_PLACEHOLDER',
    value = '',
    buttonLabel = 'COMMON.SEARCH',
    showButton = false,
    buttonVariant = 'search-input',
    onChange,
    onEnter,
    onButtonClick,
    buttonProps = {},
    inputProps = {},
    disabled = false,
    disabledAction = false,
    wrapperProps = {},
    showButtonLoader = false,
    searchResult = [],
    clearSearchResults,
    onPaste,
    ...rest
  },
  { t },
) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (disabledAction) {
      return;
    }

    if (onEnter && e.key === 'Enter') {
      onEnter(value);
    }
  };

  const handleButtonClick = (e) => {
    if (disabledAction) {
      return;
    }

    if (onButtonClick) {
      onButtonClick(value);

      if (clearSearchResults) {
        clearSearchResults();
      }
    }
  };

  const handleOnPaste = (e) => {
    if (onPaste) {
      onPaste(e.clipboardData.getData('Text'));
    }
  };

  const inputStyles = showButton ? { borderRight: 'none', borderTopRightRadius: Theme.space[0], borderBottomRightRadius: Theme.space[0] } : {};

  return (
    <Flex width="100%" alignItems="baseline" {...rest}>
      <Box position="relative" width="100%" {...rest}>
        <DefaultInput
          name={name}
          meta={{ touched: false, error: '', invalid: false }}
          label={label ? t(label) : ''}
          placeholder={placeholder ? t(placeholder) : ''}
          onChange={handleChange}
          value={value}
          onKeyDown={handleKeyDown}
          style={{ ...Theme.inputTypes.default, ...inputStyles }}
          labelStyle={styles.label}
          wrapperProps={{ ...{ width: ['100%', 'auto', 'auto'] }, ...wrapperProps }}
          disabled={disabled}
          onPaste={handleOnPaste}
          {...inputProps}
        />
      </Box>
      <RenderIf show={showButton}>
        <Button
          variant={buttonVariant}
          onClick={handleButtonClick}
          style={{ ...searchStyle.defaultButton }}
          mt={Theme.space[8]}
          disabled={disabled || disabledAction}
          showLoader={showButtonLoader}
          {...buttonProps}
        >
          {t(buttonLabel)}
        </Button>
      </RenderIf>
    </Flex>
  );
};

SearchInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  buttonLabel: PropTypes.string,
  showButton: PropTypes.bool,
  buttonVariant: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onButtonClick: PropTypes.func,
  buttonProps: PropTypes.object,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  disabledAction: PropTypes.bool,
  wrapperProps: PropTypes.object,
  showButtonLoader: PropTypes.bool,
  searchResult: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
      text: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
    }),
  ),
  clearSearchResults: PropTypes.func,
  onPaste: PropTypes.func,
};

SearchInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SearchInput;
