import { format } from 'date-fns';

import constants from '../../../common/constants';
import { HANDLE_GET_BICYCLE_TYPES, HANDLE_GET_USER_BICYCLES, HANDLE_SELECTED_BICYCLE } from '../constants/action-types';
import { getMyBicyclesApi, getBicycleTypesApi, saveBicycleDetailsImageApi, updateBicycleDetailsApi } from '../../../api/requests/bicycles-api';
import { getUploadUrlApi, uploadFileApi } from '../../../api/requests/shared-requests';
import { handleApiError } from '../../../common/helpers';
import { formatBikeImages } from '../helpers/bicycles-helpers';
import { toggleNotificationModal } from '../../../components/notification-modal/notification-modal-actions';
import { NOTIFICATION_TYPES } from '../../../components/notification-modal/notification-modal-constants';

export const getMyBicycles = () => {
  return async (dispatch) => {
    try {
      const response = await getMyBicyclesApi();
      let bicycles = response?.data?.data?.results;
      let bike = {};

      if (bicycles && bicycles.length > 0) {
        bicycles = await Promise.all(
          bicycles
            .filter((item) => item.is_active)
            .map(async (item) => {
              return { ...item, images: await formatBikeImages(Object.assign({}, item), Object.assign([], item.images)) };
            }),
        );
        bike = Object.assign({}, bicycles[0]);
        bike.purchase_date = bike.purchase_date ? format(new Date(bike.purchase_date), 'yyyy-MM-dd') : null;
      }

      dispatch(handleSelectedBicycle(bike));
      dispatch(handleGetUserBicycles(bicycles));
    } catch (error) {
      handleApiError(error);
    }
  };
};

export const getBicycleTypes = () => {
  return async (dispatch) => {
    try {
      const response = await getBicycleTypesApi();
      let bicycleTypes = response?.data?.data?.results;

      if (bicycleTypes && bicycleTypes.length > 0) {
        bicycleTypes = bicycleTypes.map((type) => {
          return { ...type, value: type.id, text: type.name };
        });
      }

      dispatch(handleGetBicycleTypes(bicycleTypes));
    } catch (error) {
      handleApiError(error);
    }
  };
};

export const uploadBikeDetailsImage = (file, fileName, contentType, bicycle = {}, bicycles = [], index) => {
  return async (dispatch) => {
    try {
      if (bicycle && bicycle.id) {
        const response = await getUploadUrlApi(fileName, contentType, constants.UPLOADS.bicycleDetailsImage);
        const url = response?.data?.data?.url;
        const accessUrl = response?.data?.data?.access_url;

        await uploadFileApi(file, url);
        await saveBicycleDetailsImageApi(bicycle.id, accessUrl);
        bicycle.images[index].url = accessUrl;
        const bicycleIndex = bicycles.findIndex((item) => item.id === bicycle.id);

        if (bicycleIndex > -1) {
          bicycles[bicycleIndex] = Object.assign({}, bicycle);
          dispatch(handleGetUserBicycles(bicycles));
        }

        dispatch(handleSelectedBicycle(bicycle));
      }
    } catch (error) {
      dispatch(toggleNotificationModal(true, NOTIFICATION_TYPES.error, 'COMMON.DEFAULT_ERROR'));
    }
  };
};

export const updateBicycleDetails = (data, bicycles = []) => {
  return async (dispatch) => {
    try {
      await updateBicycleDetailsApi(data);

      data.purchase_date = data.purchase_date ? format(new Date(data.purchase_date), 'yyyy-MM-dd') : null;
      const index = bicycles.findIndex((item) => item.id === data.id);
      bicycles[index] = data;

      dispatch(handleGetUserBicycles(bicycles));
      dispatch(handleSelectedBicycle(data));
      dispatch(toggleNotificationModal(true, NOTIFICATION_TYPES.success, 'BICYCLES.SUCCESS_SAVE_BIKE_DETAILS'));
    } catch (error) {
      dispatch(toggleNotificationModal(true, NOTIFICATION_TYPES.error, 'COMMON.DEFAULT_ERROR'));
    }
  };
};

export const handleGetUserBicycles = (bicycles = []) => {
  return {
    type: HANDLE_GET_USER_BICYCLES,
    data: bicycles,
  };
};

export const handleSelectedBicycle = (bicycle = {}) => {
  return {
    type: HANDLE_SELECTED_BICYCLE,
    data: bicycle,
  };
};

export const handleGetBicycleTypes = (bicycleTypes = []) => {
  return {
    type: HANDLE_GET_BICYCLE_TYPES,
    data: bicycleTypes,
  };
};
