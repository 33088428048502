export const LOGIN = {
  method: 'POST',
  route: '/account/login',
};

export const REGISTER = {
  method: 'POST',
  route: '/account/register',
};

export const FORGOT_PASSWORD = {
  method: 'POST',
  route: '/account/forgot-password',
};

export const GET_NEW_REFRESH_TOKEN = {
  method: 'POST',
  route: '/account/refresh-token',
};
