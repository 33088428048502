import React from 'react';
import { Hyperlink } from 'styled-react-ui-libs';

import constants from '../../../common/constants';
import Theme from '../../../theme';
import Text from '../../../components/text/text';

const BicycleTop = () => {
  return (
    <>
      <Text
        pt={[Theme.space[24], Theme.space[48], Theme.space[48]]}
        fontSize={Theme.fontSizes[24]}
        color={Theme.colors.black}
        lineHeight={Theme.lineHeights[20]}
        fontWeight={Theme.fontWeights.bold}
        textAlign="center"
      >
        {'BICYCLE.TITLE'}
      </Text>
      <Text
        pt={[Theme.space[17], Theme.space[35], Theme.space[35]]}
        fontSize={[Theme.fontSizes[18], Theme.fontSizes[20], Theme.fontSizes[22]]}
        color={Theme.colors.black}
        lineHeight={[Theme.lineHeights[20], Theme.lineHeights[26], Theme.lineHeights[26]]}
        textAlign="center"
        translationObject={{
          contactEmail: (
            <Hyperlink href={`mailto:${constants.MIO_CONTACT_EMAIL}`} color={Theme.colors.blues[1]}>
              {constants.MIO_CONTACT_EMAIL}
            </Hyperlink>
          ),
        }}
      >
        {'BICYCLE.SUBTITLE'}
      </Text>
    </>
  );
};

export default BicycleTop;
