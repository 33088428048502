import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MdError } from 'react-icons/md';
import { FaCheck, FaInfoCircle } from 'react-icons/fa';
import { Box, Button, Flex, Modal, RenderIf, Text } from 'styled-react-ui-libs';

import Theme from '../../theme';
import { NOTIFICATION_TYPES } from './notification-modal-constants';
import { toggleNotificationModal } from './notification-modal-actions';

const NotificationModal = (_, { t }) => {
  const dispatch = useDispatch();
  const {
    open,
    type,
    message,
    messageComponent,
    messageTranslationObject,
    handleClose,
    title,
    titleTranslationObject,
    actionButtonText,
    handleActionButtonClick,
    icon,
    secondActionButtonText,
    handleSecondActionButtonClick,
    titleProps,
  } = useSelector((store) => store.notificationModalReducer);

  const getIcon = () => {
    if (icon) {
      return icon;
    }

    switch (type) {
      case NOTIFICATION_TYPES.error:
        return <MdError style={{ fontSize: Theme.fontSizes[35], color: Theme.colors.danger }} />;
      case NOTIFICATION_TYPES.success:
        return <FaCheck style={{ fontSize: Theme.fontSizes[35], color: Theme.colors.success }} />;
      case NOTIFICATION_TYPES.info:
        return <FaInfoCircle style={{ fontSize: Theme.fontSizes[35], color: Theme.colors.primary }} />;
      case NOTIFICATION_TYPES.warning:
        return <FaInfoCircle style={{ fontSize: Theme.fontSizes[35], color: Theme.colors.warning }} />;
      default:
        return null;
    }
  };

  const getTitleMessage = () => {
    if (title) {
      return title;
    }

    switch (type) {
      case NOTIFICATION_TYPES.error:
        return 'COMMON.ERROR';
      case NOTIFICATION_TYPES.success:
        return 'COMMON.SUCCESS';
      case NOTIFICATION_TYPES.info:
        return 'COMMON.INFO';
      case NOTIFICATION_TYPES.warning:
        return 'COMMON.WARNING';
      default:
        return '';
    }
  };

  const getTitle = () => {
    return (
      <Flex alignItems="center">
        {getIcon()}
        <Text
          ml={Theme.space[8]}
          fontWeight={Theme.fontWeights.bold}
          fontSize={Theme.fontSizes[32]}
          lineHeight={Theme.lineHeights[38]}
          fontFamily={Theme.fonts.sansPro}
          color={Theme.colors.black}
          {...(titleProps || {})}
        >
          {t(getTitleMessage(), titleTranslationObject || {})}
        </Text>
      </Flex>
    );
  };

  const getMessage = () => {
    if (messageComponent) {
      return messageComponent;
    }

    return (
      <Text fontSize={Theme.fontSizes[16]} lineHeight={Theme.lineHeights[19]} fontFamily={Theme.fonts.sansPro} color={Theme.colors.black}>
        {t(message, messageTranslationObject || {})}
      </Text>
    );
  };

  const onClose = () => {
    if (handleClose) {
      handleClose();
    }

    dispatch(toggleNotificationModal(false));
  };

  const onActionButtonClick = () => {
    if (handleActionButtonClick) {
      handleActionButtonClick();
      return;
    }

    dispatch(toggleNotificationModal(false));
  };

  return (
    <Modal
      titleComponent={getTitle()}
      minWidth={['95%', '480px', '500px']}
      open={open}
      handleClose={onClose}
      closeButtonStyles={{ color: Theme.colors.greys[4] }}
      wrapperProps={{ zIndex: '99999' }}
      blockCloseOnOuterClick
    >
      <Box pb={Theme.space[40]} pt={Theme.space[16]}>
        {getMessage()}
      </Box>
      <Flex pt="24px" justifyContent="flex-end">
        <RenderIf show={secondActionButtonText && handleSecondActionButtonClick ? true : false}>
          <Button
            variant="primary"
            height={Theme.space[38]}
            minWidth="132px"
            mr={Theme.space[8]}
            style={{ fontWeight: Theme.fontWeights.normal, fontFamily: Theme.fonts.sansPro }}
            onClick={handleSecondActionButtonClick}
          >
            {t(secondActionButtonText || '')}
          </Button>
        </RenderIf>
        <Button
          variant="success"
          height={Theme.space[38]}
          minWidth="132px"
          style={{ fontWeight: Theme.fontWeights.normal, fontFamily: Theme.fonts.sansPro }}
          dataTestId="notification-primary-button"
          onClick={onActionButtonClick}
        >
          {t(actionButtonText)}
        </Button>
      </Flex>
    </Modal>
  );
};

NotificationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default NotificationModal;
