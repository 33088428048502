import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Button, DefaultInput, DefaultSelect, Flex, Grid, GridItem } from 'styled-react-ui-libs';

import BicyclesImages from './bicycles-images';
import DefaultDatePicker from '../../../components/default-date-picker/default-date-picker';
import DropdownWithInput from '../../../components/dropdown-with-input/dropdown-with-input';
import ElementRequiredLabel from '../../../components/element-required-label/element-required-lable';
import Theme from '../../../theme';
import styles from '../../../common/styles';
import constants from '../../../common/constants';
import { scrollToFirstError } from '../../../common/helpers';
import { FORM_NAME, FIELDS, SUBMIT_BTN_TEST_ID, AGE_AND_CONDITIONS, BRAKES_OPTIONS, TYRE_WIDTHS, TYRE_DIAMETERS, VALVE_OPTIONS } from '../constants/bicycles-constants';
import { validate } from '../validators/bicycle-validator';

const BicyclesForm = ({ handleSubmit, initialValues = {}, change, asyncValidate, bicycle = {}, bicycleTypes = [], language, images = [], handleSaveBicycle, handleUploadImage }, { t }) => {
  const { type, bicycleId, model, price, lockModel, purchaseDate, ageAndCondition, tyreWidth, tyreDiameter, valveType, gearsAndDrivetrain, frontBreak } = FIELDS;
  const selectDisabledStyles = bicycle[bicycleId.name] ? { backgroundColor: Theme.colors.disabled, color: Theme.colors.black } : {};

  const groupSets = useMemo(() => {
    return Object.keys(constants.GROUP_SETS).map((key) => constants.GROUP_SETS[key]);
  }, []);

  const ageAndConditions = useMemo(() => {
    return AGE_AND_CONDITIONS.map((item) => {
      return { ...item, text: t(item.text) };
    });
    // eslint-disable-next-line
  }, [t]);

  const breakOptions = useMemo(() => {
    return BRAKES_OPTIONS.map((item) => {
      return { ...item, text: t(item.text) };
    });
    // eslint-disable-next-line
  }, [t]);

  const tyreWidths = useMemo(() => {
    return TYRE_WIDTHS.map((item) => {
      return { ...item, text: t(item.text) };
    });
    // eslint-disable-next-line
  }, [t]);

  const tyreDiameters = useMemo(() => {
    return TYRE_DIAMETERS.map((item) => {
      return { ...item, text: t(item.text) };
    });
    // eslint-disable-next-line
  }, [t]);

  const valveOptions = useMemo(() => {
    return VALVE_OPTIONS.map((item) => {
      return { ...item, text: t(item.text) };
    });
    // eslint-disable-next-line
  }, [t]);

  return (
    <form onSubmit={handleSubmit(handleSaveBicycle)} action="#" noValidate>
      <Grid gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr']} gridColumnGap={Theme.space[24]} gridRowGap={Theme.space[4]}>
        <GridItem pb={[Theme.space[24], Theme.space[0], Theme.space[0]]}>
          <Field
            component={DefaultSelect}
            options={bicycleTypes}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            hideArrow={bicycle[bicycleId.name] ? true : false}
            label={t(type.label)}
            placeholder={t(type.placeholder)}
            name={type.name}
            dataTestId={type.dataTestId}
            id={type.dataTestId}
            value={initialValues[type.name] || ''}
            labelStyle={styles.label}
            tabIndex="1"
            withAbsoluteError
            style={{ ...styles.select, ...selectDisabledStyles }}
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DefaultInput}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            label={t(bicycleId.label)}
            name={bicycleId.name}
            dataTestId={bicycleId.dataTestId}
            id={bicycleId.dataTestId}
            value={initialValues[bicycleId.name] || ''}
            labelStyle={styles.label}
            tabIndex="2"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DefaultInput}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            label={t(model.label)}
            name={model.name}
            dataTestId={model.dataTestId}
            id={model.dataTestId}
            value={initialValues[model.name] || ''}
            labelStyle={styles.label}
            tabIndex="3"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DefaultSelect}
            options={ageAndConditions}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            hideArrow={bicycle[bicycleId.name] ? true : false}
            label={t(ageAndCondition.label)}
            placeholder={t(ageAndCondition.placeholder)}
            name={ageAndCondition.name}
            dataTestId={ageAndCondition.dataTestId}
            id={ageAndCondition.dataTestId}
            value={initialValues[ageAndCondition.name] || ''}
            labelStyle={styles.label}
            tabIndex="4"
            withAbsoluteError
            style={{ ...styles.select, ...selectDisabledStyles }}
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DropdownWithInput}
            formName={FORM_NAME}
            name={tyreWidth.name}
            initialValue={initialValues[tyreWidth.name]}
            options={tyreWidths}
            fieldOptions={tyreWidth}
            optionsKey="value"
            disabled={bicycle[bicycleId.name] ? true : false}
            changeField={change}
            required
            tabIndex="5"
            asyncValidate={asyncValidate}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DropdownWithInput}
            formName={FORM_NAME}
            name={tyreDiameter.name}
            initialValue={initialValues[tyreDiameter.name]}
            options={tyreDiameters}
            fieldOptions={tyreDiameter}
            optionsKey="value"
            disabled={bicycle[bicycleId.name] ? true : false}
            changeField={change}
            required
            tabIndex="6"
          />
        </GridItem>
        <GridItem>
          <Field
            component={DefaultSelect}
            options={valveOptions}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            hideArrow={bicycle[bicycleId.name] ? true : false}
            label={t(valveType.label)}
            placeholder={t(valveType.placeholder)}
            name={valveType.name}
            dataTestId={valveType.dataTestId}
            id={valveType.dataTestId}
            value={initialValues[valveType.name] || ''}
            labelStyle={styles.label}
            tabIndex="7"
            withAbsoluteError
            style={{ ...styles.select, ...selectDisabledStyles }}
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DropdownWithInput}
            formName={FORM_NAME}
            name={frontBreak.name}
            initialValue={initialValues[frontBreak.name]}
            options={breakOptions}
            fieldOptions={frontBreak}
            optionsKey="value"
            disabled={bicycle[bicycleId.name] ? true : false}
            changeField={change}
            required
            tabIndex="9"
          />
        </GridItem>
        <GridItem position="relative">
          <Flex
            position="absolute"
            bottom={Theme.space[20]}
            paddingX={Theme.space[12]}
            height={Theme.space[36]}
            alignItems="center"
            justifyContent="center"
            borderRight={`${Theme.borders[1]} ${Theme.colors.defaultBorderColor}`}
            color={Theme.colors.blacks[1]}
            fontWeight={Theme.fontWeights['lighter-bold']}
            fontSize={Theme.fontSizes[14]}
            lineHeight={Theme.lineHeights[16]}
            zIndex="1"
            errorStyles={styles.errors}
          >
            {language === constants.LANGUAGES.sv.code ? constants.CURRENCIES.sek.name : constants.CURRENCIES.gbp.name}
          </Flex>
          <Field
            component={DefaultInput}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            label={t(price.label)}
            name={price.name}
            dataTestId={price.dataTestId}
            id={price.dataTestId}
            value={initialValues[price.name] || ''}
            labelStyle={styles.label}
            style={{ paddingLeft: Theme.space[55] }}
            type="number"
            tabIndex="10"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem position="relative">
          <Field
            component={DropdownWithInput}
            formName={FORM_NAME}
            name={gearsAndDrivetrain.name}
            initialValue={initialValues[gearsAndDrivetrain.name]}
            options={groupSets}
            fieldOptions={gearsAndDrivetrain}
            optionsKey="value"
            disabled={bicycle[bicycleId.name] ? true : false}
            changeField={change}
            required
            tabIndex="11"
          />
        </GridItem>
        <GridItem pb={[Theme.space[24], Theme.space[0], Theme.space[0]]}>
          <Field
            component={DefaultDatePicker}
            required
            elementRequired={<ElementRequiredLabel />}
            disabled={bicycle[bicycleId.name] ? true : false}
            label={t(purchaseDate.label)}
            name={purchaseDate.name}
            dataTestId={purchaseDate.dataTestId}
            id={purchaseDate.dataTestId}
            value={initialValues[purchaseDate.name] || ''}
            tabIndex="12"
            dateFormat="yyyy-MM-dd"
            renderCalendarIcon
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </GridItem>
        <GridItem>
          <Field
            component={DefaultInput}
            disabled={bicycle[bicycleId.name] ? true : false}
            label={t(lockModel.label)}
            name={lockModel.name}
            dataTestId={lockModel.dataTestId}
            id={lockModel.dataTestId}
            value={initialValues[lockModel.name] || ''}
            labelStyle={styles.label}
            tabIndex="13"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </GridItem>
      </Grid>
      <BicyclesImages images={images} handleUploadImage={handleUploadImage} />
      <Flex>
        <Flex ml="auto" minWidth={['100%', '100px', '100px']}>
          <Button
            type="submit"
            variant="primary"
            mt={Theme.space[20]}
            style={{
              width: '100%',
              fontSize: Theme.fontSizes[18],
              lineHeight: Theme.lineHeights[24],
              fontWeight: Theme.fontWeights.bold,
              paddingTop: Theme.space[10],
              paddingBottom: Theme.space[10],
            }}
            dataTestId={SUBMIT_BTN_TEST_ID}
          >
            {t('BICYCLES.SUBMIT_BUTTON')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

BicyclesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

BicyclesForm.propTypes = {
  bicycle: PropTypes.object,
  bicycleTypes: PropTypes.array,
  images: PropTypes.array,
  handleSaveBicycle: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM_NAME,
  validate,
  initialValues: {},
  enableReinitialize: true,
  destroyOnUnmount: true,
  onSubmitFail: (errors) => scrollToFirstError(errors),
})(BicyclesForm);
