import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Flex } from 'styled-react-ui-libs';

import Theme from '../../../theme';
import BicycleTop from './bicycle-top';
import BicycleProfile from './bicycle-profile';
import { getBicycle, handleGetBicycle, handleGetBicycleFields } from '../actions/bicycle-actions';
import { FIELDS } from '../constants/bicycle-constants';

const BicycleContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const bicycle = useSelector((store) => store.bicycleReducer.bicycle);
  const bicycleFields = useSelector((store) => store.bicycleReducer.bicycleFields);
  const serial = params?.serial;

  useEffect(() => {
    dispatch(getBicycle(serial));

    return () => {
      dispatch(handleGetBicycle({}));
      dispatch(handleGetBicycleFields(FIELDS));
    };
  }, [dispatch, serial]);

  return (
    <Container ml={Theme.space[0]} mr={Theme.space[0]} paddingX={Theme.space[0]} pt={Theme.space[0]} pb={Theme.space[0]} height="100%" maxWidth="100%">
      <Flex width="100%">
        <Flex width="100%" alignItems="center" justifyContent="center">
          <Flex width={['100%', '100%', '60%', '60%']} flexDirection="column" px={[Theme.space[10], Theme.space[10], Theme.space[0], Theme.space[0]]} alignItems="center" justifyContent="center">
            <BicycleTop />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        paddingX={[Theme.space[10], Theme.space[0], Theme.space[0]]}
        my={[Theme.space[20], Theme.space[30], Theme.space[40]]}
        alignItems="center"
        justifyContent="center"
        background="rgba(243, 245, 246, 0.53)"
      >
        <BicycleProfile bicycle={bicycle} bicycleFields={bicycleFields} />
      </Flex>
    </Container>
  );
};

export default BicycleContainer;
