export const FORM_NAME = 'registrationForm';

export const SUBMIT_BTN_TEST_ID = 'registration-form-button';

export const FIELDS = {
  firstName: {
    name: 'first_name',
    label: 'REGISTRATION.FIRST_NAME',
    errorRequired: 'REGISTRATION.FIRST_NAME_REQUIRED',
    dataTestId: 'first-name-registration',
  },
  lastName: {
    name: 'last_name',
    label: 'REGISTRATION.LAST_NAME',
    errorRequired: 'REGISTRATION.LAST_NAME_REQUIRED',
    dataTestId: 'last-name-registration',
  },
  email: {
    name: 'email',
    label: 'REGISTRATION.EMAIL',
    errorRequired: 'REGISTRATION.EMAIL_REQUIRED',
    errorValid: 'REGISTRATION.EMAIL_VALID',
    dataTestId: 'email-registration',
  },
  phoneNumber: {
    name: 'phone_number',
    label: 'REGISTRATION.PHONE_NUMBER',
    errorRequired: 'REGISTRATION.PHONE_NUMBER_REQUIRED',
    dataTestId: 'phone-number-registration',
  },
  password: {
    name: 'password',
    label: 'REGISTRATION.PASSWORD',
    errorRequired: 'REGISTRATION.PASSWORD_REQUIRED',
    errorValid: 'REGISTRATION.PASSWORD_VALID',
    dataTestId: 'password-registration',
  },
  termsAndConditions: {
    name: 'terms_and_conditions',
    errorRequired: 'REGISTRATION.TERMS_REQUIRED',
    dataTestId: 'terms-and-conditions-registration',
  },
};
