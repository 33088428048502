import React from 'react';
import PropTypes from 'prop-types';

const Translator = ({ translationObject = {}, children }, { t }) => <>{t(children, translationObject)}</>;

Translator.contextTypes = {
  t: PropTypes.func.isRequired,
};

Translator.propTypes = {
  translationObject: PropTypes.object,
};

export default Translator;
