import { GET_MY_BICYCLES, GET_BICYCLE_TYPES, SAVE_BICYCLE_DETAILS_IMAGE, UPDATE_BICYCLE_DETAILS } from '../request-objects/bicycles-requests';
import { formAxiosObject } from '../../common/helpers';

export const getMyBicyclesApi = () => {
  return formAxiosObject(GET_MY_BICYCLES);
};

export const getBicycleTypesApi = () => {
  return formAxiosObject(GET_BICYCLE_TYPES);
};

export const saveBicycleDetailsImageApi = (bicycleId, url) => {
  return formAxiosObject(SAVE_BICYCLE_DETAILS_IMAGE, { bicycle_id: bicycleId, url });
};

export const updateBicycleDetailsApi = (data) => {
  return formAxiosObject(UPDATE_BICYCLE_DETAILS, data);
};

export const getBicycleApi = (serial) => {
  return formAxiosObject({
    route: `/search-bicycles/${serial}/details`,
    method: 'GET',
  });
};
