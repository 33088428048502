import { formAxiosObject } from '../../common/helpers';
import { GET_UPLOAD_URL } from '../request-objects/shared-objects';

export const getUploadUrlApi = (fileName, contentType, kind) => {
  return formAxiosObject(GET_UPLOAD_URL, null, { filename: fileName, 'content-type': contentType, kind });
};

export const uploadFileApi = (file, url) => {
  return formAxiosObject(
    { method: 'PUT', route: url },
    file,
    null,
    null,
    {
      'Content-Type': file.type,
      'x-goog-acl': 'public-read',
    },
    true,
  );
};
