import { searchBicycleApi } from '../../../api/requests/search-api';
import { NOTIFICATION_TYPES } from '../../../components/notification-modal/notification-modal-constants';
import { getErrorMessage } from '../helpers/search-helpers';
import { toggleNotificationModal } from '../../../components/notification-modal/notification-modal-actions';

export const searchBicycle = (serial, onDone) => {
  return async (dispatch) => {
    try {
      const response = await searchBicycleApi(serial);

      const exists = response?.data?.data?.exists;

      if (exists) {
        onDone();
        return;
      }

      dispatch(
        toggleNotificationModal(
          true,
          NOTIFICATION_TYPES.info,
          'SEARCH.BICYCLE_DOES_NOT_EXISTS',
          null,
          null,
          null,
          null,
          () => {
            dispatch(toggleNotificationModal(false));
          },
          { serial },
        ),
      );
    } catch (error) {
      const errorCause = error?.response?.data?.error?.message;
      const message = getErrorMessage(errorCause);

      dispatch(toggleNotificationModal(true, NOTIFICATION_TYPES.error, message));
    }
  };
};
