import Dashboard from '../app/dashboard/components/dashboard';
import AuthContainer from '../app/auth/components/auth-container';
import ForgotPasswordContainer from '../app/forgot-password/components/forgot-password-container';
import SearchContainer from '../app/search/components/search-container';
import BicyclesContainer from '../app/bicycles/components/bicycles-container';
import BicycleContainer from '../app/bicycle/components/bicycle-container';

const prefixes = {
  home: 'HOME',
  auth: 'AUTH',
  forgotPassword: 'FORP',
  search: 'SRCH',
  bicycles: 'BICY',
  bicycle: 'BICL',
};

const routes = {
  home: {
    path: '/',
    component: Dashboard,
    prefix: prefixes.home,
  },
  auth: {
    path: '/auth/:tab',
    fullPath: '/auth/',
    component: AuthContainer,
    prefix: prefixes.auth,
  },
  forgotPassword: {
    path: '/forgot-password',
    component: ForgotPasswordContainer,
    prefix: prefixes.forgotPassword,
  },
  search: {
    path: '/search',
    component: SearchContainer,
    prefix: prefixes.search,
  },
  bicycles: {
    path: '/bicycles',
    component: BicyclesContainer,
    prefix: prefixes.bicycles,
    isProtectedRoute: true,
  },
  bicycle: {
    path: '/bicycle/:serial',
    fullPath: '/bicycle/',
    component: BicycleContainer,
    prefix: prefixes.bicycle,
  },
};

export default {
  routes,
};
