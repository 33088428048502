import React from 'react';

import Text from '../../../components/text/text';
import Theme from '../../../theme';

const SearchTop = () => {
  return (
    <>
      <Text
        fontSize={[Theme.fontSizes[35], Theme.fontSizes[50], Theme.fontSizes[50]]}
        color={Theme.colors.black}
        lineHeight={Theme.lineHeights[44]}
        fontWeight={Theme.fontWeights.bold}
        textAlign="center"
      >
        {'SEARCH.TITLE'}
      </Text>
      <Text
        pt={[Theme.space[17], Theme.space[35], Theme.space[35]]}
        fontSize={[Theme.fontSizes[17], Theme.fontSizes[25], Theme.fontSizes[25]]}
        color={Theme.colors.black}
        lineHeight={[Theme.lineHeights[20], Theme.lineHeights[40], Theme.lineHeights[40]]}
        textAlign="center"
      >
        {'SEARCH.SUBTITLE'}
      </Text>
      <Text
        pt={[Theme.space[30], '70px', '70px']}
        fontSize={[Theme.fontSizes[15], Theme.fontSizes[21], Theme.fontSizes[21]]}
        color={Theme.colors.black}
        lineHeight={Theme.lineHeights[24]}
        textAlign="center"
      >
        {'SEARCH.SUBTITLE_PRE_SEARCH'}
      </Text>
    </>
  );
};

export default SearchTop;
