export const FIELDS = [
  {
    name: 'bicycle_type',
    label: 'BICYCLE.TYPE',
    value: '',
  },
  {
    name: 'sol_number',
    tookSerial: true,
    label: 'BICYCLE.BICYCLE_ID',
    value: '',
  },
  {
    name: 'name',
    label: 'BICYCLE.MODEL',
    value: '',
  },
  {
    name: 'purchase_date',
    label: 'BICYCLE.PURCHASE_DATE',
    value: '',
  },
  {
    name: 'lock_model',
    label: 'BICYCLE.LOCK_MODEL',
    value: '',
  },
  {
    name: 'city',
    label: 'BICYCLE.OWNER_LOCATION',
    value: '',
  },
];

export const priceNameField = 'price';
