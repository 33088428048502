import { borders } from './borders';
import { radii } from './radii';
import { space } from './space';
import colors from './colors';
import { shadows } from './box-shadows';
import { fontSizes } from './font-sizes';

export const inputTypes = {
  default: {
    border: borders[1],
    borderColor: colors.defaultBorderColor,
    boxShadow: shadows.none,
    '&::placeholder': {
      color: colors.greys[1],
    },
    '&:hover': {
      borderColor: colors.greys[2],
    },
    '&:focus': {
      outline: 'none',
      boxShadow: shadows.inputFocus,
    },
    '&:disabled': {
      opacity: 0.25,
      backgroundColor: colors.greys[1],
    },
  },
};

export const inputSizes = {
  sm: {
    fontSize: fontSizes.sm,
    padding: `${space.sm / 2}px ${space.sm}px`,
    borderRadius: radii.sm,
  },
  md: {
    fontSize: fontSizes.base,
    padding: `${space.sm}px ${space.md}px`,
    borderRadius: radii.md,
  },
  lg: {
    fontSize: fontSizes.lg,
    padding: `${space.sm}px ${space.md}px`,
    borderRadius: radii.md,
  },
};
