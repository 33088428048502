import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Box, Button, DefaultInput, Flex, RenderIf } from 'styled-react-ui-libs';

import Text from '../../../components/text/text';
import Theme from '../../../theme';
import styles from '../../../common/styles';
import routesConstants from '../../../common/routes-constants';
import { FORM_NAME, FIELDS, SUBMIT_BTN_TEST_ID } from '../constants/login-constants';
import { validate } from '../validators/login-validator';

const LoginForm = ({ handleSubmit, initialValues = {}, handleLogin, errorMsg }, { t }) => {
  const [isPasswordField, setIsPasswordField] = useState(true);
  const { email, password } = FIELDS;

  const passwordInputIconStyle = { fontSize: Theme.fontSizes[24], position: 'absolute', right: Theme.space[5], top: Theme.space[31], color: Theme.colors.greys[4], cursor: 'pointer' };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleLogin)} action="#" noValidate>
        <Text color={Theme.colors.black} fontWeight={Theme.fontWeights.bold} fontSize={Theme.fontSizes[18]} lineHeight={Theme.lineHeights[20]} textAlign="center" mb={Theme.space[8]}>
          {'LOGIN.TITLE'}
        </Text>
        <Text color={Theme.colors.black} fontSize={Theme.fontSizes[14]} lineHeight={Theme.lineHeights[16]} textAlign="center" mb={Theme.space[24]}>
          {'LOGIN.SUBTITLE'}
        </Text>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultInput}
            required
            label={t(email.label)}
            placeholder={t(email.placeholder)}
            name={email.name}
            dataTestId={email.dataTestId}
            id={email.dataTestId}
            value={initialValues[email.name] || ''}
            labelStyle={styles.label}
            tabIndex="1"
            type="email"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </Box>
        <Box mb={Theme.space[5]} position="relative">
          <Field
            component={DefaultInput}
            required
            label={t(password.label)}
            placeholder={t(password.placeholder)}
            name={password.name}
            dataTestId={password.dataTestId}
            id={password.dataTestId}
            value={initialValues[password.name] || ''}
            style={{
              paddingRight: Theme.space[35],
            }}
            labelStyle={styles.label}
            type={isPasswordField ? 'password' : 'text'}
            tabIndex="2"
            withAbsoluteError
            errorStyles={styles.errors}
          />
          {isPasswordField ? (
            <IoMdEye style={passwordInputIconStyle} onClick={() => setIsPasswordField(!isPasswordField)} />
          ) : (
            <IoMdEyeOff style={passwordInputIconStyle} onClick={() => setIsPasswordField(!isPasswordField)} />
          )}
        </Box>
        <Box mt={Theme.space[24]}>
          <Button variant="primary" type="submit" style={styles.fullWidth} dataTestId={SUBMIT_BTN_TEST_ID}>
            {t('LOGIN.BUTTON')}
          </Button>
        </Box>
      </form>
      <Flex pt={Theme.space[24]} alignItems="center" justifyContent="center">
        <Link style={{ color: Theme.colors.blues[1], fontSize: Theme.fontSizes[14], lineHeight: Theme.lineHeights[16], textDecoration: 'none' }} to={routesConstants.routes.forgotPassword.path}>
          {t('LOGIN.RESET_PASSWORD')}
        </Link>
      </Flex>
      <RenderIf show={errorMsg ? true : false}>
        <Text color={Theme.colors.danger} fontSize={Theme.fontSizes[14]} lineHeight={Theme.lineHeights[16]} textAlign="center" mt={Theme.space[15]}>
          {errorMsg}
        </Text>
      </RenderIf>
    </Box>
  );
};

LoginForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

LoginForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORM_NAME,
  validate,
  initialValues: {
    email: '',
    password: '',
  },
  enableReinitialize: true,
  destroyOnUnmount: true,
})(LoginForm);
