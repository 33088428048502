import { TOGGLE_NOTIFICATION_MODAL } from './action-types';

export const toggleNotificationModal = (
  open,
  type,
  message,
  messageComponent,
  handleClose,
  title,
  actionButtonText,
  handleActionButtonClick,
  messageTranslationObject,
  titleTranslationObject,
  icon,
  secondActionButtonText,
  handleSecondActionButtonClick,
  titleProps,
) => {
  return {
    type: TOGGLE_NOTIFICATION_MODAL,
    data: {
      open,
      type,
      message,
      messageComponent,
      handleClose,
      title,
      actionButtonText,
      handleActionButtonClick,
      messageTranslationObject,
      titleTranslationObject,
      icon,
      secondActionButtonText,
      handleSecondActionButtonClick,
      titleProps,
    },
  };
};
