import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { space, layout, color, flexbox, background, compose, position, border, typography, shadow } from 'styled-system';
import { Flex, Image } from 'styled-react-ui-libs';

import Theme from '../../theme';
import bikePlaceholder from '../../common/images/bike-placeholder.svg';
import styles from './styles';
import { generateRandomString } from '../../common/helpers';

const UploadBike = ({ id = `upload-file-${generateRandomString(16)}`, inputTestId = '', acceptFileTypes = '', acceptMultiple = false, imageStyles = {}, imageProps = {}, onChange, ...rest }) => {
  const StyledUploadButton = styled('input')(compose(space, layout, color, flexbox, background, position, border, typography, shadow));

  const handImageClick = () => {
    const element = document.getElementById(id);

    if (element) {
      element.click();
    }
  };

  const handleInputChange = (e) => {
    const allFiles = e.target.files;

    if (allFiles && allFiles.length > 0 && onChange) {
      const files = acceptMultiple ? allFiles : [allFiles[0]];
      onChange(files);
    }

    e.target.value = '';
  };

  return (
    <>
      <Flex height="100%" justifyContent="center" alignItems="center" borderRadius={Theme.space[8]} background={Theme.colors.white} onClick={handImageClick}>
        <Image src={bikePlaceholder} alt={`upload-image-${id}`} style={{ ...styles.image, ...imageStyles }} {...imageProps} />
      </Flex>
      <StyledUploadButton type="file" accept={acceptFileTypes} style={styles.input} multiple={acceptMultiple} id={id} onChange={handleInputChange} data-testid={inputTestId} {...rest} />
    </>
  );
};

UploadBike.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputTestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  acceptFileTypes: PropTypes.string,
  acceptMultiple: PropTypes.bool,
  imageStyles: PropTypes.object,
  imageProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default UploadBike;
