import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FiCalendar } from 'react-icons/fi';
import { Box, Flex, RenderIf, Text } from 'styled-react-ui-libs';

import Label from '../label/label';
import Theme from '../../theme';
import styles from '../../common/styles';

import 'react-datepicker/dist/react-datepicker.css';

const DefaultDatePicker = (
  {
    meta: { touched, error },
    label,
    placeholder = '',
    dataTestId,
    name,
    input = {},
    dateFormat = 'dd.MM.yyyy HH:mm',
    timeFormat = 'HH:mm',
    timeInputLabel = '',
    showTimeInput = false,
    renderCalendarIcon = false,
    pickerClassName = '',
    wrapperStyle = {},
    required = false,
    elementRequired,
    isClearable = false,
    showTimeSelectOnly = false,
    showTimeSelect = false,
    errorProps = {},
    withoutError = false,
    errorStyles = {},
    disabled = false,
    ...rest
  },
  { t },
) => (
  <Box position="relative">
    {label && <Label label={label} required={required} elementRequired={elementRequired} labelStyle={styles.label} />}
    <Box border={`${Theme.borders[1]} ${touched && error ? Theme.colors.danger : Theme.colors.defaultBorderColor}`} borderRadius="3px" position="relative" mt="8px" style={wrapperStyle}>
      <Flex alignItems="center">
        <RenderIf show={renderCalendarIcon}>
          <Box paddingX={Theme.space[12]} position="absolute" left={Theme.space[0]} zIndex="1" height="100%" borderRight={`${Theme.borders[1]} ${Theme.colors.defaultBorderColor}`}>
            <FiCalendar style={{ zIndex: 1, marginTop: Theme.space[10], color: Theme.colors.blacks[1] }} />
          </Box>
        </RenderIf>
        <DatePicker
          className={`default-date-picker ${renderCalendarIcon ? 'default-date-picker-with-icon' : ''} ${disabled ? 'default-date-picker-disabled' : ''} ${pickerClassName}`}
          {...input}
          {...rest}
          selected={input.value ? new Date(input.value) : null}
          name={name}
          dateFormat={dateFormat}
          timeInputLabel={timeInputLabel ? t(timeInputLabel) : ''}
          showTimeInput={showTimeInput}
          placeholderText={placeholder ? t(placeholder) : ''}
          dateFormatCalendar={dateFormat}
          timeFormat={timeFormat}
          isClearable={isClearable}
          showTimeSelectOnly={showTimeSelectOnly}
          showTimeSelect={showTimeSelect}
          data-testid={dataTestId}
          disabled={disabled}
        />
      </Flex>
    </Box>
    {!withoutError && (
      <Box position="absolute" left="0" bottom="-20px" {...errorProps}>
        {touched && error && (
          <Text color={Theme.colors.danger} fontSize={Theme.fontSizes.xs} style={errorStyles}>
            {error}
          </Text>
        )}
      </Box>
    )}
  </Box>
);

DefaultDatePicker.contextTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.object,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
  timeInputLabel: PropTypes.string,
  showTimeInput: PropTypes.bool,
  renderCalendarIcon: PropTypes.bool,
  pickerClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  required: PropTypes.bool,
  elementRequired: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  isClearable: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  withoutError: PropTypes.bool,
};

export default DefaultDatePicker;
