export const formatBikeImages = async (bike, images = []) => {
  const result = [];
  let counter = 4;

  if (bike.image) {
    result.push({ url: bike.image });
    images.shift();
    counter -= 1;
  }

  for (let i = 0; i < counter; i++) {
    const reorderedImages = Object.assign([], images).reverse();

    if (reorderedImages && reorderedImages.length && reorderedImages[i]) {
      result.push(reorderedImages[i]);
    } else {
      result.push({
        url: '',
      });
    }
  }

  return result;
};
