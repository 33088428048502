import { formAxiosObject } from '../../common/helpers';
import { LOGIN, REGISTER, FORGOT_PASSWORD, GET_NEW_REFRESH_TOKEN } from '../request-objects/auth-requests';

export const loginApi = (email, password) => {
  return formAxiosObject(LOGIN, { email, password });
};

export const registrationApi = (firstName, lastName, phoneNumber, email, password, language, country) => {
  return formAxiosObject(REGISTER, { first_name: firstName, last_name: lastName, phone_number: phoneNumber, email, password, language, country });
};

export const forgotPasswordApi = (email) => {
  return formAxiosObject(FORGOT_PASSWORD, { email });
};

export const getNewRefreshTokenApi = (token) => {
  return formAxiosObject(GET_NEW_REFRESH_TOKEN, { refresh_token: token });
};
