import colors from './styles/colors';
import { borders } from './styles/borders';
import { fonts } from './styles/fonts';
import { shadows } from './styles/box-shadows';
import { buttons, buttonSizes } from './styles/buttons';
import { fontSizes } from './styles/font-sizes';
import { space } from './styles/space';
import { lineHeights } from './styles/line-heights';
import { fontWeights } from './styles/font-weights';
import { breakpointsPixels } from './styles/breakpoints-pixels';
import { inputTypes, inputSizes } from './styles/inputs';

const theme = {
  borders,
  buttons,
  buttonSizes,
  colors,
  fonts,
  fontWeights,
  lineHeights,
  fontSizes,
  space,
  shadows,
  breakpointsPixels,
  // eslint-disable-next-line no-magic-numbers
  width: [16, 32, 64, 128, 256],
  breakpoints: ['40em', '52em', '64em', '80em'],
  inputSizes,
  inputTypes,
};

export default theme;
