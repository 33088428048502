const local = {
  ENVIRONMENT: 'local',
  API_URL: 'http://localhost:8000',
  STORAGE_SECRET_KEY: 'eeF61nwT759KAdA',
  APP_URL: 'http://localhost:3333',
};

const development = {
  ENVIRONMENT: 'development',
  API_URL: 'https://dev-api.mioo.cc',
  STORAGE_SECRET_KEY: 'zR8ov9YWzWod3np',
  APP_URL: '',
};

const production = {
  ENVIRONMENT: 'production',
  API_URL: 'https://api-v2.mioo.cc',
  STORAGE_SECRET_KEY: 'LFLIe2VfquQbrOt',
  APP_URL: '',
};

const getConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return local;
    case 'production':
      return production;
    default:
      return development;
  }
};

export default {
  SOURCE: 'web-freemium',
  // eslint-disable-next-line
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line
  PASSWORD_REGEX: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!$%@#£€*?&^()-_=+"'{}|~`±§]{6,40}$/,
  // eslint-disable-next-line
  USERNAME_REGEX: /^(?=.*[A-Za-z\d])[A-Za-z\d-_.]{4,40}$/,
  EN_TERMS_AND_CONDITIONS_LINK: 'https://mioo.cc/en/privacypolicy/',
  SV_TERMS_AND_CONDITIONS_LINK: 'https://mioo.cc/sv/om-mioo/integritetspolicy/',
  MIO_CONTACT_EMAIL: 'hello@mioo.cc',
  LANGUAGES: {
    en: {
      code: 'en',
      label: 'English',
    },
    sv: {
      code: 'sv',
      label: 'Swedish',
    },
  },
  CURRENCIES: {
    gbp: {
      name: 'GBP',
      symbol: '£',
    },
    sek: {
      name: 'SEK',
      symbol: 'kr',
    },
  },
  GROUP_SETS: {
    nexus3: {
      value: 'Nexus 3',
      text: 'Nexus 3',
    },
    nexus7: {
      value: 'Nexus 7',
      text: 'Nexus 7',
    },
    speed7: {
      value: '7 Speed Cassette',
      text: '7 Speed Cassette',
    },
    speed8: {
      value: '8 Speed Cassette',
      text: '8 Speed Cassette',
    },
    speed9: {
      value: '9 Speed Cassette',
      text: '9 Speed Cassette',
    },
    speed10: {
      value: '10 Speed Cassette',
      text: '10 Speed Cassette',
    },
    speed11: {
      value: '11 Speed Cassette',
      text: '11 Speed Cassette',
    },
    srams5: {
      value: 'Sram S5',
      text: 'Sram S5',
    },
    srams7: {
      value: 'Sram S7',
      text: 'Sram S7',
    },
    torpedo3: {
      value: 'Torpedo 3',
      text: 'Torpedo 3',
    },
    other: {
      value: 'Other',
      text: 'Other',
    },
  },
  UPLOADS: {
    bicycleDetailsImage: 'bicycle_details_image',
  },
  COUNTRIES: {
    sweden: {
      code: 'se',
    },
    england: {
      code: 'gb',
    },
  },
  OTHER_DROPDOWN_VALUE: 'Other',
  ...getConfig(),
};
