import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Box, Button, DefaultInput, RenderIf } from 'styled-react-ui-libs';

import Text from '../../../components/text/text';
import Theme from '../../../theme';
import styles from '../../../common/styles';
import { FORM_NAME, FIELDS, SUBMIT_BTN_TEST_ID } from '../constants/forgot-password-constants';
import { validate } from '../validators/forgot-password-validator';

const ForgotPasswordForm = ({ handleSubmit, initialValues = {}, handleForgotPassword, errorMsg }, { t }) => {
  const { email } = FIELDS;

  return (
    <Box>
      <form onSubmit={handleSubmit(handleForgotPassword)} action="#" noValidate>
        <Text color={Theme.colors.black} fontWeight={Theme.fontWeights.bold} fontSize={Theme.fontSizes[18]} lineHeight={Theme.lineHeights[20]} textAlign="center" mb={Theme.space[8]}>
          {'FORGOT_PASSWORD.TITLE'}
        </Text>
        <Text color={Theme.colors.black} fontSize={Theme.fontSizes[12]} lineHeight={Theme.lineHeights[16]} textAlign="center" mb={Theme.space[24]}>
          {'FORGOT_PASSWORD.SUBTITLE'}
        </Text>
        <Box mb={Theme.space[5]}>
          <Field
            component={DefaultInput}
            required
            label={t(email.label)}
            placeholder={t(email.placeholder)}
            name={email.name}
            dataTestId={email.dataTestId}
            id={email.dataTestId}
            value={initialValues[email.name] || ''}
            labelStyle={styles.label}
            tabIndex="1"
            type="email"
            withAbsoluteError
            errorStyles={styles.errors}
          />
        </Box>
        <Box mt={Theme.space[24]}>
          <Button variant="primary" type="submit" style={styles.fullWidth} dataTestId={SUBMIT_BTN_TEST_ID}>
            {t('FORGOT_PASSWORD.BUTTON')}
          </Button>
        </Box>
        <RenderIf show={errorMsg ? true : false}>
          <Text color={Theme.colors.danger} fontSize={Theme.fontSizes[14]} lineHeight={Theme.lineHeights[16]} textAlign="center" mt={Theme.space[15]}>
            {errorMsg}
          </Text>
        </RenderIf>
      </form>
    </Box>
  );
};

ForgotPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

ForgotPasswordForm.propTypes = {
  handleForgotPassword: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
};

export default reduxForm({
  form: FORM_NAME,
  validate,
  initialValues: {
    email: '',
  },
  enableReinitialize: true,
  destroyOnUnmount: true,
})(ForgotPasswordForm);
