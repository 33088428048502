import { lighten } from 'polished';

import colors from './colors';
import { fontSizes } from './font-sizes';
import { space } from './space';
import { radii } from './radii';
import { fontWeights } from './font-weights';
import { borders } from './borders';

const DARKEN_AMOUNT = 0.1;

export const buttons = {
  primary: {
    color: colors.white,
    backgroundColor: colors.primary,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes[14],
    borderRadius: radii[22],
    paddingBottom: space[15],
    paddingTop: space[15],
    paddingLeft: space[20],
    paddingRight: space[20],
    width: 'auto',
    ':hover': {
      color: `${colors.white} !important`,
      backgroundColor: `${lighten(DARKEN_AMOUNT, colors.primary)} !important`,
    },
  },
  'search-input': {
    color: colors.white,
    backgroundColor: colors.primary,
    fontWeight: fontWeights.bold,
    fontSize: fontSizes[14],
    paddingBottom: space[15],
    paddingTop: space[15],
    paddingLeft: space[20],
    paddingRight: space[20],
    width: 'auto',
    ':hover': {
      color: `${colors.white} !important`,
      backgroundColor: `${lighten(DARKEN_AMOUNT, colors.primary)} !important`,
    },
  },
  default: {
    color: colors.blacks[1],
    backgroundColor: colors.white,
    fontWeight: fontWeights.normal,
    fontSize: fontSizes[14],
    borderRadius: radii[22],
    paddingBottom: space[10],
    paddingTop: space[10],
    paddingLeft: space[10],
    paddingRight: space[10],
    border: `${borders[1]} ${colors.greys[4]}`,
    width: 'auto',
    ':hover': {
      color: `${colors.blacks[1]} !important`,
      backgroundColor: `${lighten(DARKEN_AMOUNT, colors.white)} !important`,
    },
  },
  disabled: {
    backgroundColor: colors.greys[4],
    color: colors.white,
    fontWeight: fontWeights.bold,
    cursor: 'not-allowed',
  },
};

export const buttonSizes = {};
