import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from 'styled-react-ui-libs';

import routesConstants from '../../common/routes-constants';
import Theme from '../../theme';
import { ReactComponent as Logo } from '../../common/images/logo.svg';

const Header = () => {
  const history = useHistory();

  return (
    <Box position="fixed" background={Theme.colors.black} width="100%" height={['60px', '100px', '140px']} top="0" zIndex="99999999">
      <Flex pl={[Theme.space[26], Theme.space[41], Theme.space[56]]} alignItems="center" height="100%">
        <Logo
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push(routesConstants.routes.search.path);
          }}
        />
      </Flex>
    </Box>
  );
};

export default Header;
