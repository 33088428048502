import { HANDLE_LANGUAGE_CHANGE } from '../constants/action-types';

const INITIAL_STATE = {
  language: 'sv',
};

export const cachedReducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    case HANDLE_LANGUAGE_CHANGE:
      return { ...state, language: payload.data };
    default:
      return state;
  }
};
