import axios from 'axios';

import constants from './constants';

export const blockPageScroll = () => {
  if (document.body.className.indexOf('loader-block-scroll') === -1) {
    document.body.className += ' loader-block-scroll';
  }
};

export const unblockPageScroll = () => {
  document.body.className = document.body.className.split('loader-block-scroll').join('');
};

export const formAxiosObject = (requestObject, data, params, urlParams, headers, isExternalRequest = false) => {
  if (!requestObject || !requestObject.route || !requestObject.method) {
    return;
  }

  let { route, method } = requestObject;

  if (urlParams) {
    for (let key in urlParams) {
      route += `/${urlParams[key]}`;
    }
  }

  const axiosObject = {
    url: route,
    method: requestObject.method,
    isExternalRequest,
  };

  if (data && (method === 'POST' || method === 'PUT' || method === 'DELETE')) {
    axiosObject.data = data;
  }

  if (params && requestObject.method === 'GET') {
    axiosObject.params = params;
  }

  if (headers) {
    axiosObject.headers = headers;
  }

  return axios(axiosObject);
};

export const handleApiError = (error) => {
  // in the future here we might add here sending error
  // towards some external service like Sentry for logging errors

  if (constants.ENVIRONMENT === 'local' || constants.ENVIRONMENT === 'development') {
    console.log('%c (Dev/Local Environment): Some shit happened and this is the error:\n', 'background: #DE3640; color: #FFF; font-size: 16px; font-weight: 700', error);
  }
};

export const generateRandomString = (length) => {
  let result = '';
  const possible = 'abcdefghijklmnopqrstuvwxyz0123456789-_';

  for (let i = 0; i < length; i += 1) {
    result += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return result;
};

export const scrollToFirstError = (errors = {}) => {
  let inputs;

  for (let property in errors) {
    inputs = document.querySelectorAll(`[name="${property}"]`);

    if (inputs.length && inputs[0] && inputs[0].scrollIntoView) {
      inputs[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      break;
    }
  }
};

export const scrollTo = (elementId, block = 'center', inline = 'nearest') => {
  const element = document.getElementById(elementId);

  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block,
      inline,
    });
  }
};
