export const baseColors = {
  black: '#000000',
  white: '#FFFFFF',
  primary: '#5093CC',
  danger: '#DE3640',
  success: '#00CE3C',
  warning: '#F5A623',

  defaultBorderColor: '#CCCCCC',
  disabled: '#F1F4F7',

  greys: {
    1: '#CBD5E0',
    2: '#718096',
    3: '#CBCBCB',
    4: '#CCCCCC',
    5: '#EFF3F6',
    6: '#5F7D95',
    7: '#DAE1E6',
  },

  oranges: {},

  blacks: {
    1: '#555555',
  },

  reds: {},

  blues: {
    1: '#5093CC',
  },

  greens: {},

  yellows: {},

  other: {},
};

const colors = {
  black: baseColors.black,
  white: baseColors.white,
  primary: baseColors.primary,
  danger: baseColors.danger,
  success: baseColors.success,
  warning: baseColors.warning,

  defaultBorderColor: baseColors.defaultBorderColor,
  disabled: baseColors.disabled,

  greys: baseColors.greys,
  oranges: baseColors.oranges,
  blacks: baseColors.blacks,
  reds: baseColors.reds,
  blues: baseColors.blues,
  greens: baseColors.greens,
  yellows: baseColors.yellows,
  other: baseColors.other,
};

export default colors;
