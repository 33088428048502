import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Grid, GridItem, Image } from 'styled-react-ui-libs';

import Theme from '../../../theme';
import Text from '../../../components/text/text';
import bikePlaceholder from '../../../common/images/bike-placeholder.svg';
import { priceNameField } from '../constants/bicycle-constants';
import { formatPrice } from '../helpers/bicycle-helper';

const BicycleProfile = ({ bicycle, bicycleFields = [] }) => {
  return (
    <Box width={['100%', '70%', '50%']}>
      <Flex flexDirection="column" pb={[Theme.space[20], Theme.space[30], Theme.space[40]]}>
        {bicycleFields &&
          bicycleFields.map(({ name, label, value }) => (
            <Flex key={`bicycle-field-${name}`} paddingY={Theme.space[8]} borderBottom={`${Theme.borders[1]} ${Theme.colors.greys[7]}`}>
              <Text fontSize={Theme.fontSizes[16]} color={Theme.colors.greys[6]} lineHeight={Theme.lineHeights[23]}>
                {label}
              </Text>
              <Text nonTranslatable ml="auto" fontSize={Theme.fontSizes[16]} fontWeight={Theme.fontWeights.bold} color={Theme.colors.black} lineHeight={Theme.lineHeights[23]}>
                {`${value} ${name === priceNameField ? formatPrice(value, name, bicycle?.country) : ''}`}
              </Text>
            </Flex>
          ))}
      </Flex>
      <Grid mt={Theme.space[24]} gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr']} gridGap={[Theme.space[10], Theme.space[16], Theme.space[24]]}>
        {bicycle &&
          bicycle.images &&
          bicycle.images.map(({ url }, index) => (
            <GridItem key={`bike-image-${index}`} borderRadius={Theme.space[8]} height="100%" minHeight="300px" pb={Theme.space[15]}>
              <Flex alignItems="center" justifyContent="center" background={Theme.colors.white} height="100%">
                <Image
                  src={`${url}?${new Date().getTime()}`}
                  alt={`bike-image-${index}`}
                  style={{ width: '100%', height: '300px', borderRadius: Theme.space[8], cursor: 'pointer' }}
                  id={`bike-uploaded-image-${index}`}
                  onClick={() => window.open(url, '_blank').focus()}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = bikePlaceholder;
                    e.target.style = 'background: white; width: 40%;';
                  }}
                />
              </Flex>
            </GridItem>
          ))}
      </Grid>
    </Box>
  );
};

BicycleProfile.propTypes = {
  bicycle: PropTypes.object,
  bicycleFields: PropTypes.array,
};

export default BicycleProfile;
