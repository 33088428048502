export const getErrorMessage = (error) => {
  switch (error) {
    case 'user_exists':
      return 'REGISTRATION.USER_EXISTS';
    case 'data_is_not_ok':
      return 'REGISTRATION.DATA_NOT_OK';
    default:
      return 'COMMON.DEFAULT_ERROR';
  }
};
